import React, { Component } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { Stack, InputGroup, InputLeftElement, Input } from "@chakra-ui/react"
import { SearchIcon } from "@chakra-ui/icons"

class SearchBox extends Component {
  timerId = null;

  state = {
    value: this.props.currentRefinement
  };

  onChangeDebounced = event => {
    const { refine, delay } = this.props;
    const value = event.currentTarget.value;

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => refine(value), delay);

    this.setState(() => ({
      value
    }));
  };

  render() {
    const { value } = this.state;

    return (
      <Stack spacing={2} mb="5">
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray.300" />}
          />
          <Input
            w="80vw"
            color="black"
            bgColor="white"
            value={value}
            onChange={this.onChangeDebounced}
            placeholder="Buscar recetas" />
        </InputGroup>
      </Stack>
    );
  }
}

const DebouncedSearchBox = connectSearchBox(SearchBox);

export default DebouncedSearchBox