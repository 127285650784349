import React from 'react';
import { Image, keyframes, usePrefersReducedMotion } from '@chakra-ui/react';
import logo from './logo-white.svg';
import logoVertical from './logo-vertical.svg';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Logo = props => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const { isVertical, ...rest } = props

  // eslint-disable-next-line no-unused-vars
  const animation = prefersReducedMotion
    ? undefined
    : `${spin} infinite 20s linear`;

  return <Image src={ isVertical ? logoVertical : logo} {...rest} />;
};

Logo.defaultProps = {
  isVertical: false
}

export default Logo
