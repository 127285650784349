import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import { useLocation } from 'react-router-dom';
import get from 'lodash.get';
import {
  Box,
  Flex,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  Heading,
  Text,
  Checkbox,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Spinner,
  Spacer,
  Radio,
  RadioGroup,
  Stack,
  Select,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';
import {
  Formik,
  Form,
  Field,
  FieldArray,
  useFormikContext,
  useField,
} from 'formik';
import { DeleteIcon } from '@chakra-ui/icons';
import CompositionTable from '../../../components/organisms/CompositionTable';
import Summary from './summary';
import ItemSearch from '../../../components/molecules/ItemSearch';
import DebouncedInput from '../../../components/atoms/DebouncedInput';
import Uploader from '../../../components/molecules/Uploader';
import { withFirebase } from '../../../utils/Firebase';
import { withAuthorization } from '../../../context/Session';
import numericQuantity from '../../../utils/fractions';

const CookedPaxField = props => {
  const {
    values: { totalWeightCooked, servings },
    touched,
    setFieldValue,
  } = useFormikContext();
  const [field, meta] = useField(props);

  useEffect(() => {
    // set the value of textC, based on textA and textB
    if (
      totalWeightCooked !== '' &&
      servings !== '' &&
      touched.servings &&
      touched.totalWeightCooked
    ) {
      setFieldValue(props.name, totalWeightCooked / servings);
    }
  }, [
    totalWeightCooked,
    servings,
    touched.totalWeightCooked,
    touched.servings,
    setFieldValue,
    props.name,
  ]);

  return (
    <>
      <Input {...props} {...field} />
      {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
    </>
  );
};

const TotalRawWeigthField = props => {
  const {
    values: { ingredients, type },
    touched,
    setFieldValue,
  } = useFormikContext();
  const [field, meta] = useField(props);

  useEffect(() => {
    if (ingredients.length > 0 && touched.ingredients) {
      const localIngredients =
        type === 'drinks'
          ? ingredients.filter(i => i.unit === 'oz')
          : ingredients;
      setFieldValue(
        props.name,
        localIngredients.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.weight) || 0,
          0
        )
      );
    }
  }, [ingredients, touched.ingredients, setFieldValue, props.name, type]);

  return (
    <>
      <Input {...props} {...field} />
      {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
    </>
  );
};

const GeneralF1Field = props => {
  const {
    values: { servings, newServings },
    touched,
    setFieldValue,
  } = useFormikContext();
  const [field] = useField(props);

  useEffect(() => {
    if (touched.newServings || touched.servings) {
      const newValue =
        newServings && newServings >= 0 ? newServings / servings : 0;
      setFieldValue(props.name, newValue.toFixed(3));
    }
  }, [
    servings,
    newServings,
    touched.servings,
    touched.newServings,
    setFieldValue,
    props.name,
  ]);

  return (
    <>
      <Input disabled {...props} {...field} />
    </>
  );
};

const GeneralF2Field = props => {
  const {
    values: { servings, newServings, totalWeightNewServings, paxWeightCooked },
    touched,
    setFieldValue,
  } = useFormikContext();
  const [field] = useField(props);

  useEffect(() => {
    if (
      touched.newServings ||
      touched.servings ||
      touched.paxWeightCooked ||
      touched.totalWeightNewServings
    ) {
      const firstValue =
        newServings && newServings >= 0
          ? newServings * totalWeightNewServings
          : 0;
      const secondValue = servings * paxWeightCooked;
      const newValue = firstValue / secondValue;
      setFieldValue(props.name, newValue.toFixed(3));
    }
  }, [
    servings,
    newServings,
    totalWeightNewServings,
    paxWeightCooked,
    touched.servings,
    touched.newServings,
    touched.paxWeightCooked,
    touched.totalWeightNewServings,
    setFieldValue,
    props.name,
  ]);

  return (
    <>
      <Input disabled {...props} {...field} />
    </>
  );
};

const EditRecipe = ({ firebase }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenAlert,
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);
  const [currentRecipe, setCurrentRecipe] = useState(null);
  const [currentRecipeId, setCurrentRecipeId] = useState(null);
  const [image, setImage] = useState('');
  const [progress, setProgress] = useState(0);
  const cancelRef = React.useRef();
  const { addToast } = useToasts();
  let location = useLocation();

  useEffect(() => {
    const getRecipe = async () => {
      const { state } = location;
      const recipeId = state.hit.objectID;
      setCurrentRecipeId(recipeId);
      const current = await firebase.getRecipe({
        recipeId,
      });
      setCurrentRecipe(current);
      setIsLoading(false);
    };
    if (location && location.state && firebase) {
      getRecipe();
    }
  }, [location, firebase]);

  const getF1Value = (weight = 0, f1 = 0) => {
    const newValue = f1 * weight;
    return newValue.toFixed(3);
  };

  const getF1Drinks = (weight = 0, newServings = 0, servings, unit) => {
    const currentW = numericQuantity(weight);
    const unitWeigth = currentW / servings;
    let newValue = 0;
    try {
      if (isNaN(currentW)) {
        return 0;
      }
      if (newServings === 0) {
        return 0;
      }
      if (unit === 'oz') {
        newValue = unitWeigth * newServings * 29.57;
        return newValue.toFixed(3);
      }
      newValue = newServings * unitWeigth;
      return newValue.toFixed(3);
    } catch (error) {
      return 0;
    }
  };

  const getF2Value = (weight, f2) => {
    const newValue = f2 * weight;
    return newValue.toFixed(3);
  };

  function validateRequired(value) {
    let error;
    if (!value) {
      error = 'Valor es requerido';
    }
    return error;
  }

  function validateNumberRequired(value) {
    let error;
    if (!value || value < 0) {
      error = 'Valor no es valido';
    }
    return error;
  }

  if (isLoading)
    return (
      <Flex
        h="100vh"
        w="calc(100vw - 60px)"
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Spinner color="brand.corona" size="lg" />
        <Text mt="2" color="brand.corona" size="lg">
          Cargando receta
        </Text>
      </Flex>
    );

  return (
    <Flex
      direction="column"
      align="center"
      justify="flex-start"
      h="100vh"
      minW="calc(100vw - 58px)"
      overflowX="auto"
    >
      <Heading color="white" my="3">
        Receta: {get(currentRecipe, 'name', ' ')}
      </Heading>
      <Box
        borderRadius="lg"
        w="calc(80vw - 60px)"
        minW="300px"
        maxW="container.lg"
        bgColor="brand.white"
        p="6"
        mb="20"
      >
        <Tabs mt={2} variant="enclosed">
          <TabList>
            <Tab outline="none">Editar</Tab>
            <Tab outline="none">Resumen</Tab>
          </TabList>
          <TabPanels>
            <TabPanel px="0">
              <Formik
                initialValues={{
                  ...currentRecipe,
                  type: currentRecipe.type || 'food',
                }}
                onSubmit={async (values, actions) => {
                  try {
                    const authToken = await firebase.getIdToken();
                    const mappedIngredients = values.ingredients.map(i => {
                      return {
                        ...i,
                        ...(values.type === 'food' && {
                          f1: getF1Value(i.weight, values.f1),
                          f2: getF2Value(i.weight, values.f2),
                        }),
                      };
                    });
                    if (image && image.name) {
                      const imgTask = firebase.uploadToStorage(image);
                      imgTask.on(
                        'state_changed',
                        snapshot => {
                          const progress =
                            Math.round(
                              snapshot.bytesTransferred / snapshot.totalBytes
                            ) * 100;
                          setProgress(progress);
                        },
                        error => {
                          actions.setSubmitting(false);
                        },
                        () => {
                          imgTask.snapshot.ref
                            .getDownloadURL()
                            .then(async url => {
                              const createdRecipe = await fetch(
                                'https://us-central1-chef-fran.cloudfunctions.net/recipesUpdate',
                                {
                                  method: 'POST',
                                  body: JSON.stringify(
                                    {
                                      recipe: {
                                        ...values,
                                        ingredients: mappedIngredients,
                                        imageUrl: url,
                                      },
                                      recipeId: currentRecipeId,
                                    },
                                    null,
                                    2
                                  ),
                                  headers: {
                                    Authorization: `Bearer ${authToken}`,
                                    'Content-Type': 'application/json',
                                  },
                                }
                              );
                              const data = await createdRecipe.json();
                              if (data.error) {
                                addToast(
                                  'Ocurrio un error inesperado, intenta nuevamente',
                                  { appearance: 'error' }
                                );
                                actions.setSubmitting(false);
                              } else {
                                addToast(data.message, {
                                  appearance: 'success',
                                });
                                actions.setSubmitting(false);
                                actions.resetForm();
                              }
                            });
                        }
                      );
                    } else {
                      const createdRecipe = await fetch(
                        'https://us-central1-chef-fran.cloudfunctions.net/recipesUpdate',
                        {
                          method: 'POST',
                          body: JSON.stringify(
                            {
                              recipe: {
                                ...values,
                                ingredients: mappedIngredients,
                              },
                              recipeId: currentRecipeId,
                            },
                            null,
                            2
                          ),
                          headers: {
                            Authorization: `Bearer ${authToken}`,
                            'Content-Type': 'application/json',
                          },
                        }
                      );
                      const data = await createdRecipe.json();
                      if (data.error) {
                        addToast(
                          'Ocurrio un error inesperado, intenta nuevamente',
                          { appearance: 'error' }
                        );
                      } else {
                        setCurrentRecipe(JSON.parse(data.data.edited));
                        addToast(data.message, { appearance: 'success' });
                      }
                      actions.setSubmitting(false);
                    }
                  } catch (error) {
                    actions.setSubmitting(false);
                  }
                }}
              >
                {props => (
                  <Form noValidate={true}>
                    <AlertDialog
                      motionPreset="slideInBottom"
                      leastDestructiveRef={cancelRef}
                      onClose={onCloseAlert}
                      isOpen={isOpenAlert}
                      isCentered
                    >
                      <AlertDialogOverlay />
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          Datos nutricionales?
                        </AlertDialogHeader>
                        <AlertDialogCloseButton />
                        <AlertDialogBody>
                          Desea generar los datos nutricionales de la receta?.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                          <Button
                            ref={cancelRef}
                            onClick={() => {
                              props.resetForm();
                              onCloseAlert();
                            }}
                          >
                            No
                          </Button>
                          <Button
                            colorScheme="red"
                            ml={3}
                            onClick={() => {
                              onCloseAlert();
                              onOpen();
                            }}
                          >
                            Si
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                    <SimpleGrid columns={3} spacing={4}>
                      <Field name="name" validate={validateRequired}>
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={form.errors.name && form.touched.name}
                          >
                            <FormLabel htmlFor="name">Nombre</FormLabel>
                            <Input {...field} id="name" placeholder="Nombre" />
                            <FormErrorMessage>
                              {form.errors.name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="servings" validate={validateNumberRequired}>
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.servings && form.touched.servings
                            }
                          >
                            <FormLabel htmlFor="servings">
                              {props.values.type === 'food'
                                ? '# Porciones'
                                : '# Pax'}
                            </FormLabel>
                            <Input {...field} id="servings" type="number" />
                            <FormErrorMessage>
                              {form.errors.servings}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="newServings">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.newServings &&
                              form.touched.newServings
                            }
                          >
                            <FormLabel htmlFor="newServings">
                              Pax Nuevo
                            </FormLabel>
                            <Input
                              {...field}
                              id="newServings"
                              placeholder="0"
                            />
                            <FormErrorMessage>
                              {form.errors.newServings}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      {props.values.type === 'drinks' && (
                        <Field name="metaTime">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.metaTime && form.touched.metaTime
                              }
                            >
                              <FormLabel htmlFor="metaTime">
                                Tiempo de metabolización
                              </FormLabel>
                              <Input
                                {...field}
                                id="metaTime"
                                placeholder="1 hora"
                              />
                              <FormErrorMessage>
                                {form.errors.metaTime}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      )}
                      {props.values.type === 'food' && (
                        <Field name="totalWeightNewServings">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.totalWeightNewServings &&
                                form.touched.totalWeightNewServings
                              }
                            >
                              <FormLabel htmlFor="totalWeightNewServings">
                                Peso Pax Nuevo(g)
                              </FormLabel>
                              <Input
                                {...field}
                                id="totalWeightNewServings"
                                placeholder="0"
                              />
                              <FormErrorMessage>
                                {form.errors.totalWeightNewServings}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      )}
                      {props.values.type === 'food' && (
                        <Field name="totalRawWeightNewServings">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.totalRawWeightNewServings &&
                                form.touched.totalRawWeightNewServings
                              }
                            >
                              <FormLabel htmlFor="totalRawWeightNewServings">
                                Peso Pax(g) Crudo
                              </FormLabel>
                              <Input
                                {...field}
                                id="totalRawWeightNewServings"
                                placeholder="0"
                              />
                              <FormErrorMessage>
                                {form.errors.totalRawWeightNewServings}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      )}
                      {props.values.type === 'food' && (
                        <Field name="totalWeightCooked">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.totalWeightCooked &&
                                form.touched.totalWeightCooked
                              }
                            >
                              <FormLabel htmlFor="totalWeightCooked">
                                Peso total Cocinado(g)
                              </FormLabel>
                              <Input
                                {...field}
                                id="totalWeightCooked"
                                placeholder="0"
                              />
                              <FormErrorMessage>
                                {form.errors.totalWeightCooked}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      )}
                      {props.values.type === 'food' && (
                        <Field name="totalWeightRaw">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.totalWeightRaw &&
                                form.touched.totalWeightRaw
                              }
                            >
                              <FormLabel htmlFor="totalWeightRaw">
                                Peso total(g) Crudo
                              </FormLabel>
                              <TotalRawWeigthField
                                disabled
                                name="totalWeightRaw"
                              />
                              {/* <Input readOnly {...field} id="totalWeightRaw" placeholder="0" /> */}
                              <FormErrorMessage>
                                {form.errors.totalWeightRaw}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      )}
                      {props.values.type === 'food' && (
                        <Field name="paxWeightCooked">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.paxWeightCooked &&
                                form.touched.paxWeightCooked
                              }
                            >
                              <FormLabel htmlFor="paxWeightCooked">
                                Peso Pax Cocinado(g)
                              </FormLabel>
                              <CookedPaxField disabled name="paxWeightCooked" />
                            </FormControl>
                          )}
                        </Field>
                      )}
                      {props.values.type === 'food' && (
                        <Flex>
                          <Box px="4">
                            <Field name="f1">
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={form.errors.f1 && form.touched.f1}
                                >
                                  <FormLabel htmlFor="f1">F1</FormLabel>
                                  <GeneralF1Field
                                    {...field}
                                    id="f1"
                                    placeholder="0"
                                  />
                                  <FormErrorMessage>
                                    {form.errors.f1}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                          </Box>
                          <Spacer />
                          <Box px="4">
                            <Field name="f2">
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={form.errors.f2 && form.touched.f2}
                                >
                                  <FormLabel htmlFor="f2">F2</FormLabel>
                                  <GeneralF2Field
                                    disabled
                                    {...field}
                                    id="f2"
                                    placeholder="0"
                                  />
                                  <FormErrorMessage>
                                    {form.errors.f2}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                          </Box>
                        </Flex>
                      )}
                      {props.values.type === 'drinks' && (
                        <Box>
                          <Field name="drinkStyle" validate={validateRequired}>
                            {({ field, form }) => (
                              <FormControl
                                isInvalid={
                                  form.errors.drinkStyle &&
                                  get(form, ['touched', 'drinkStyle'])
                                }
                              >
                                <FormLabel htmlFor="drinkStyle">
                                  Estilo
                                </FormLabel>
                                {/* <Input {...field} id={`ingredients.${index}.unit`} placeholder="1" type="number" /> */}
                                <Select
                                  {...field}
                                  variant="filled"
                                  size="md"
                                  placeholder="Seleccionar"
                                >
                                  <option value="Corto">Corto</option>
                                  <option value="Corto chequeado">
                                    Corto chequeado
                                  </option>
                                  <option value="Directo">Directo</option>
                                  <option value="Mezclado">Mezclado</option>
                                  <option value="Mezclado largo">
                                    Mezclado largo
                                  </option>
                                  <option value="Licuado frappe">
                                    Licuado frappe
                                  </option>
                                </Select>
                                <FormErrorMessage>
                                  {get(form, ['errors', 'drinkStyle'])}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Box>
                      )}
                      <Flex>
                        <Uploader
                          setImage={setImage}
                          currentImage={props.values.imageUrl}
                        />
                      </Flex>
                    </SimpleGrid>
                    <Tabs mt={4}>
                      <TabList>
                        <Tab outline="none">Ingredientes</Tab>
                        {props.values.type === 'food' && (
                          <Tab outline="none">Método de cocción</Tab>
                        )}
                        <Tab outline="none">Técnicas</Tab>
                        <Tab outline="none">Menaje</Tab>
                        <Tab outline="none">Categorías</Tab>
                        <Tab outline="none">Preparación</Tab>
                        <Tab outline="none">Info. Nutricional</Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel px="0">
                          <Box>
                            <FieldArray name="ingredients">
                              {({ remove, push }) => (
                                <div>
                                  {props.values.ingredients.length > 0 &&
                                    props.values.ingredients.map(
                                      (ingredient, index) => (
                                        <SimpleGrid
                                          columns={10}
                                          spacing={1}
                                          key={index}
                                        >
                                          <Box
                                            className="col"
                                            gridColumn="span 2"
                                          >
                                            <Field
                                              name={`ingredients.${index}.name`}
                                              validate={validateRequired}
                                            >
                                              {({ field, form }) => (
                                                <FormControl
                                                  isInvalid={
                                                    get(form, [
                                                      'errors',
                                                      'ingredients',
                                                      index,
                                                      'name',
                                                    ]) &&
                                                    get(form, [
                                                      'touched',
                                                      'ingredients',
                                                      index,
                                                      'name',
                                                    ])
                                                  }
                                                >
                                                  <FormLabel
                                                    htmlFor={`ingredients.${index}.name`}
                                                  >
                                                    Descripción
                                                  </FormLabel>
                                                  {/* <Input {...field} id={`ingredients.${index}.name`} placeholder="Digitar Ingrediente" /> */}
                                                  <ItemSearch
                                                    {...field}
                                                    onSelectedProduct={f => {
                                                      if (
                                                        form.values.type ===
                                                        'drinks'
                                                      ) {
                                                        form.setFieldValue(
                                                          `ingredients.${index}.unit`,
                                                          f.unit
                                                        );
                                                        form.setFieldValue(
                                                          `ingredients.${index}.isAlcohol`,
                                                          f.isAlcohol
                                                        );
                                                        form.setFieldValue(
                                                          `ingredients.${index}.alcoholAmount`,
                                                          f.alcoholAmount
                                                        );
                                                      }
                                                      form.setFieldValue(
                                                        `ingredients.${index}.isDivider`,
                                                        f.isDivider
                                                      );
                                                    }}
                                                    // onClearProduct={handleClearProduct}
                                                    // onAnySugestions={handleSugestedProductSearch}
                                                    placeholder="Buscar Ingrediente"
                                                    indexName="ingredients"
                                                  />
                                                  <FormErrorMessage>
                                                    {get(form, [
                                                      'errors',
                                                      'ingredients',
                                                      index,
                                                      'name',
                                                    ])}
                                                  </FormErrorMessage>
                                                </FormControl>
                                              )}
                                            </Field>
                                          </Box>
                                          <div className="col">
                                            <Field
                                              name={`ingredients.${index}.weight`}
                                              validate={
                                                props.values.ingredients[index]
                                                  .isDivider
                                                  ? null
                                                  : validateNumberRequired
                                              }
                                            >
                                              {({ field, form }) => (
                                                <FormControl
                                                  isInvalid={
                                                    get(form, [
                                                      'errors',
                                                      'ingredients',
                                                      index,
                                                      'weight',
                                                    ]) &&
                                                    get(form, [
                                                      'touched',
                                                      'ingredients',
                                                      index,
                                                      'weight',
                                                    ])
                                                  }
                                                >
                                                  <FormLabel
                                                    htmlFor={`ingredients.${index}.weight`}
                                                  >
                                                    {props.values.type ===
                                                    'food'
                                                      ? 'Peso(g)'
                                                      : 'Peso'}
                                                  </FormLabel>
                                                  {/* <Input {...field} id={`ingredients.${index}.weight`} placeholder="1" type="number" /> */}
                                                  <DebouncedInput
                                                    disabled={
                                                      form.values.ingredients[
                                                        index
                                                      ].isDivider
                                                    }
                                                    {...field}
                                                    id={`ingredients.${index}.weight`}
                                                    placeholder="1"
                                                    type={
                                                      get(
                                                        form,
                                                        [
                                                          'errors',
                                                          'ingredients',
                                                          index,
                                                          'unit',
                                                        ],
                                                        'oz'
                                                      ) === 'oz' &&
                                                      form.values.type ===
                                                        'drinks'
                                                        ? 'string'
                                                        : 'number'
                                                    }
                                                    delay={250}
                                                  />
                                                  <FormErrorMessage>
                                                    {get(form, [
                                                      'errors',
                                                      'ingredients',
                                                      index,
                                                      'weight',
                                                    ])}
                                                  </FormErrorMessage>
                                                </FormControl>
                                              )}
                                            </Field>
                                          </div>
                                          {props.values.type === 'drinks' && (
                                            <Box gridColumn="span 2">
                                              <Field
                                                name={`ingredients.${index}.unit`}
                                                validate={
                                                  props.values.ingredients[
                                                    index
                                                  ].isDivider
                                                    ? null
                                                    : validateRequired
                                                }
                                              >
                                                {({ field, form }) => (
                                                  <FormControl
                                                    isInvalid={
                                                      get(form, [
                                                        'errors',
                                                        'ingredients',
                                                        index,
                                                        'unit',
                                                      ]) &&
                                                      get(form, [
                                                        'touched',
                                                        'ingredients',
                                                        index,
                                                        'unit',
                                                      ])
                                                    }
                                                  >
                                                    <FormLabel
                                                      htmlFor={`ingredients.${index}.unit`}
                                                    >
                                                      Unidad
                                                    </FormLabel>
                                                    {/* <Input {...field} id={`ingredients.${index}.unit`} placeholder="1" type="number" /> */}
                                                    <Select
                                                      {...field}
                                                      variant="filled"
                                                      size="md"
                                                      placeholder="Seleccionar"
                                                      disabled={
                                                        form.values.ingredients[
                                                          index
                                                        ].isDivider
                                                      }
                                                    >
                                                      <option value="chd">
                                                        Cucharadita
                                                      </option>
                                                      <option value="gol">
                                                        Golpes
                                                      </option>
                                                      <option value="gr">
                                                        Gramos
                                                      </option>
                                                      <option value="mL">
                                                        Mililitros
                                                      </option>
                                                      <option value="oz">
                                                        Onzas
                                                      </option>
                                                      <option value="pz">
                                                        Al gusto
                                                      </option>
                                                      <option value="rell">
                                                        Relleno
                                                      </option>
                                                      <option value="und">
                                                        Unidades
                                                      </option>
                                                    </Select>
                                                    <FormErrorMessage>
                                                      {get(form, [
                                                        'errors',
                                                        'ingredients',
                                                        index,
                                                        'unit',
                                                      ])}
                                                    </FormErrorMessage>
                                                  </FormControl>
                                                )}
                                              </Field>
                                            </Box>
                                          )}
                                          {props.values.type === 'food' && (
                                            <>
                                              <div className="col">
                                                <Field
                                                  name={`ingredients.${index}.pc`}
                                                >
                                                  {({ field, form }) => (
                                                    <FormControl
                                                      isInvalid={
                                                        get(form, [
                                                          'errors',
                                                          'ingredients',
                                                          index,
                                                          'pc',
                                                        ]) &&
                                                        get(form, [
                                                          'touched',
                                                          'ingredients',
                                                          index,
                                                          'pc',
                                                        ])
                                                      }
                                                    >
                                                      <FormLabel
                                                        htmlFor={`ingredients.${index}.pc`}
                                                      >
                                                        PC
                                                      </FormLabel>
                                                      <Input
                                                        {...field}
                                                        disabled={
                                                          form.values
                                                            .ingredients[index]
                                                            .isDivider
                                                        }
                                                        id={`ingredients.${index}.pc`}
                                                        placeholder=""
                                                      />
                                                      <FormErrorMessage>
                                                        {get(form, [
                                                          'errors',
                                                          'ingredients',
                                                          index,
                                                          'pc',
                                                        ])}
                                                      </FormErrorMessage>
                                                    </FormControl>
                                                  )}
                                                </Field>
                                              </div>
                                              <div className="col">
                                                <Field
                                                  name={`ingredients.${index}.pcc`}
                                                >
                                                  {({ field, form }) => (
                                                    <FormControl
                                                      isInvalid={
                                                        get(form, [
                                                          'errors',
                                                          'ingredients',
                                                          index,
                                                          'pcc',
                                                        ]) &&
                                                        get(form, [
                                                          'touched',
                                                          'ingredients',
                                                          index,
                                                          'pcc',
                                                        ])
                                                      }
                                                    >
                                                      <FormLabel
                                                        htmlFor={`ingredients.${index}.pcc`}
                                                      >
                                                        P.C.C
                                                      </FormLabel>
                                                      <Input
                                                        {...field}
                                                        disabled={
                                                          form.values
                                                            .ingredients[index]
                                                            .isDivider
                                                        }
                                                        id={`ingredients.${index}.pcc`}
                                                        placeholder=""
                                                      />
                                                      <FormErrorMessage>
                                                        {get(form, [
                                                          'errors',
                                                          'ingredients',
                                                          index,
                                                          'pcc',
                                                        ])}
                                                      </FormErrorMessage>
                                                    </FormControl>
                                                  )}
                                                </Field>
                                              </div>
                                            </>
                                          )}
                                          {props.values.type === 'drinks' && (
                                            <Box pr="2">
                                              <Field
                                                name={`ingredients.${index}.f1`}
                                              >
                                                {({ field, form }) => (
                                                  <FormControl>
                                                    <FormLabel
                                                      htmlFor={`ingredients.${index}.f1`}
                                                    >
                                                      {get(form, [
                                                        'values',
                                                        'ingredients',
                                                        index,
                                                        'unit',
                                                      ]) === 'oz' ||
                                                      get(form, [
                                                        'values',
                                                        'ingredients',
                                                        index,
                                                        'unit',
                                                      ]) === 'mL'
                                                        ? 'F1(mL)'
                                                        : 'F1'}
                                                    </FormLabel>
                                                    <Input
                                                      disabled={
                                                        form.values.ingredients[
                                                          index
                                                        ].isDivider
                                                      }
                                                      value={
                                                        props.values.type ===
                                                        'food'
                                                          ? getF1Value(
                                                              get(form, [
                                                                'values',
                                                                'ingredients',
                                                                index,
                                                                'weight',
                                                              ]),
                                                              get(
                                                                form,
                                                                [
                                                                  'values',
                                                                  'newServings',
                                                                ],
                                                                0
                                                              )
                                                            )
                                                          : getF1Drinks(
                                                              get(form, [
                                                                'values',
                                                                'ingredients',
                                                                index,
                                                                'weight',
                                                              ]),
                                                              get(
                                                                form,
                                                                [
                                                                  'values',
                                                                  'newServings',
                                                                ],
                                                                0
                                                              ),
                                                              get(
                                                                form,
                                                                [
                                                                  'values',
                                                                  'servings',
                                                                ],
                                                                0
                                                              ),
                                                              get(form, [
                                                                'values',
                                                                'ingredients',
                                                                index,
                                                                'unit',
                                                              ])
                                                            )
                                                      }
                                                      name={field.name}
                                                      id={`ingredients.${index}.f1`}
                                                    />
                                                  </FormControl>
                                                )}
                                              </Field>
                                            </Box>
                                          )}
                                          {props.values.type === 'food' && (
                                            <Flex gridColumn="span 2">
                                              <Box pr="2">
                                                <Field
                                                  name={`ingredients.${index}.f1`}
                                                >
                                                  {({ field, form }) => (
                                                    <FormControl>
                                                      <FormLabel
                                                        htmlFor={`ingredients.${index}.f1`}
                                                      >
                                                        F1
                                                      </FormLabel>
                                                      <Input
                                                        disabled={
                                                          form.values
                                                            .ingredients[index]
                                                            .isDivider
                                                        }
                                                        value={getF1Value(
                                                          get(form, [
                                                            'values',
                                                            'ingredients',
                                                            index,
                                                            'weight',
                                                          ]),
                                                          get(form, [
                                                            'values',
                                                            'f1',
                                                          ])
                                                        )}
                                                        name={field.name}
                                                        id={`ingredients.${index}.f1`}
                                                      />
                                                    </FormControl>
                                                  )}
                                                </Field>
                                              </Box>
                                              <Box
                                                key={`box-ingredients.${index}.f2`}
                                              >
                                                <Field
                                                  key={`f-ingredients.${index}.f2`}
                                                  name={`ingredients.${index}.f2`}
                                                >
                                                  {({ field, form }) => (
                                                    <FormControl>
                                                      <FormLabel
                                                        htmlFor={`ingredients.${index}.f2`}
                                                      >
                                                        F2
                                                      </FormLabel>
                                                      <Input
                                                        disabled={
                                                          form.values
                                                            .ingredients[index]
                                                            .isDivider
                                                        }
                                                        value={getF2Value(
                                                          get(form, [
                                                            'values',
                                                            'ingredients',
                                                            index,
                                                            'weight',
                                                          ]),
                                                          get(form, [
                                                            'values',
                                                            'f2',
                                                          ])
                                                        )}
                                                        name={field.name}
                                                        id={`ingredients.${index}.f2`}
                                                      />
                                                    </FormControl>
                                                  )}
                                                </Field>
                                              </Box>
                                            </Flex>
                                          )}
                                          <Flex
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            direction="column"
                                          >
                                            <Field
                                              name={`ingredients.${index}.isDivider`}
                                            >
                                              {({ field, form }) => (
                                                <FormControl
                                                  my={2}
                                                  display="flex"
                                                  justifyContent="center"
                                                  alignItems="center"
                                                >
                                                  <Checkbox
                                                    ml={
                                                      props.values.type ===
                                                      'drinks'
                                                        ? '4px'
                                                        : '5'
                                                    }
                                                    mt={
                                                      form.values.type ===
                                                      'food'
                                                        ? '30'
                                                        : '2'
                                                    }
                                                    isChecked={field.value}
                                                    {...field}
                                                  >
                                                    Separador
                                                  </Checkbox>
                                                </FormControl>
                                              )}
                                            </Field>
                                            {props.values.type === 'drinks' && (
                                              <Field
                                                name={`ingredients.${index}.isAlcohol`}
                                              >
                                                {({ field, form }) => (
                                                  <FormControl
                                                    display="flex"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                  >
                                                    <Checkbox
                                                      ml="4px"
                                                      mt="2"
                                                      isChecked={field.value}
                                                      isDisabled={
                                                        form.values.ingredients[
                                                          index
                                                        ].isDivider
                                                      }
                                                      disabled={
                                                        form.values.ingredients[
                                                          index
                                                        ].isDivider
                                                      }
                                                      {...field}
                                                    >
                                                      Alcohol
                                                    </Checkbox>
                                                  </FormControl>
                                                )}
                                              </Field>
                                            )}
                                          </Flex>
                                          {props.values.type === 'drinks' &&
                                          get(
                                            props,
                                            [
                                              'values',
                                              'ingredients',
                                              index,
                                              'isAlcohol',
                                            ],
                                            false
                                          ) ? (
                                            <Flex mx="2">
                                              <Field
                                                name={`ingredients.${index}.alcoholAmount`}
                                                validate={validateRequired}
                                              >
                                                {({ field, form }) => (
                                                  <FormControl
                                                    isInvalid={
                                                      form.errors.type &&
                                                      form.touched.type
                                                    }
                                                  >
                                                    <FormLabel
                                                      fontWeight="bold"
                                                      htmlFor="type"
                                                    >
                                                      Alcohol:
                                                    </FormLabel>
                                                    <RadioGroup
                                                      {...field}
                                                      id="type"
                                                      defaultValue="-20"
                                                    >
                                                      <Stack direction="column">
                                                        <Radio
                                                          size="sm"
                                                          m="0"
                                                          {...field}
                                                          value="-20"
                                                        >
                                                          -20
                                                        </Radio>
                                                        <Radio
                                                          m="0"
                                                          size="sm"
                                                          {...field}
                                                          value="-40"
                                                        >
                                                          -40
                                                        </Radio>
                                                        <Radio
                                                          size="sm"
                                                          m="0"
                                                          {...field}
                                                          value="40"
                                                        >
                                                          +40
                                                        </Radio>
                                                      </Stack>
                                                    </RadioGroup>
                                                  </FormControl>
                                                )}
                                              </Field>
                                            </Flex>
                                          ) : (
                                            <Flex mx="2" />
                                          )}
                                          <Flex
                                            alignItems="flex-end"
                                            justify="center"
                                            w="100px"
                                          >
                                            <Button
                                              type="button"
                                              onClick={() => remove(index)}
                                              colorScheme="white"
                                              variant="outline"
                                            >
                                              <DeleteIcon m="0" />
                                            </Button>
                                          </Flex>
                                        </SimpleGrid>
                                      )
                                    )}
                                  <Button
                                    mt="5"
                                    type="button"
                                    className="secondary"
                                    onClick={() => {
                                      const localIngredients =
                                        props.values.type === 'drinks'
                                          ? props.values.ingredients.filter(
                                              i => i.unit === 'oz'
                                            )
                                          : props.values.ingredients;
                                      const total = localIngredients.reduce(
                                        (accumulator, currentValue) =>
                                          accumulator +
                                            parseFloat(currentValue.weight) ||
                                          0,
                                        0
                                      );
                                      props.setFieldValue(
                                        'totalWeightRaw',
                                        total
                                      );
                                      push({
                                        name: '',
                                        weight: undefined,
                                        unit:
                                          props.values.type === 'food'
                                            ? 'gr'
                                            : 'oz',
                                        order:
                                          props.values.ingredients.length + 1,
                                        isDivider: false,
                                        isAlcohol: false,
                                      });
                                    }}
                                  >
                                    Agregar Ingrediente
                                  </Button>
                                </div>
                              )}
                            </FieldArray>
                          </Box>
                        </TabPanel>

                        {props.values.type === 'food' && (
                          <TabPanel>
                            <Box>
                              <FieldArray name="cookingMethod">
                                {({ remove, push }) => (
                                  <div>
                                    {props.values.cookingMethod.length > 0 &&
                                      props.values.cookingMethod.map(
                                        (ingredient, index) => (
                                          <SimpleGrid
                                            columns={2}
                                            spacing={5}
                                            key={index}
                                          >
                                            <div className="col">
                                              <Field
                                                name={`cookingMethod.${index}.description`}
                                                validate={validateRequired}
                                              >
                                                {({ field, form }) => (
                                                  <FormControl
                                                    my={2}
                                                    isInvalid={
                                                      get(form, [
                                                        'errors',
                                                        'cookingMethod',
                                                        index,
                                                        'description',
                                                      ]) &&
                                                      get(form, [
                                                        'touched',
                                                        'cookingMethod',
                                                        index,
                                                        'description',
                                                      ])
                                                    }
                                                  >
                                                    {/* <Input {...field} id={`cookingMethod.${index}.description`} placeholder="Digitar Método" /> */}
                                                    <ItemSearch
                                                      {...field}
                                                      placeholder="Digitar Método"
                                                      indexName="cookingMethods"
                                                      indexField="description"
                                                    />
                                                    <FormErrorMessage>
                                                      {get(form, [
                                                        'errors',
                                                        'cookingMethod',
                                                        index,
                                                        'description',
                                                      ])}
                                                    </FormErrorMessage>
                                                  </FormControl>
                                                )}
                                              </Field>
                                            </div>
                                            <Flex
                                              alignItems="center"
                                              justify="center"
                                              w="100px"
                                            >
                                              <Button
                                                type="button"
                                                onClick={() => remove(index)}
                                                colorScheme="white"
                                                variant="outline"
                                              >
                                                <DeleteIcon m="0" />
                                              </Button>
                                            </Flex>
                                          </SimpleGrid>
                                        )
                                      )}
                                    <Button
                                      mt="5"
                                      type="button"
                                      className="secondary"
                                      onClick={() => {
                                        push({ description: '' });
                                      }}
                                    >
                                      Agregar Método
                                    </Button>
                                  </div>
                                )}
                              </FieldArray>
                            </Box>
                          </TabPanel>
                        )}
                        <TabPanel>
                          <Box>
                            <FieldArray name="techniques">
                              {({ remove, push }) => (
                                <div>
                                  {props.values.techniques &&
                                    props.values.techniques.length > 0 &&
                                    props.values.techniques.map(
                                      (techinique, index) => (
                                        <SimpleGrid
                                          columns={2}
                                          spacing={5}
                                          key={index}
                                        >
                                          <div className="col">
                                            <Field
                                              name={`techniques.${index}.description`}
                                              validate={validateRequired}
                                            >
                                              {({ field, form }) => (
                                                <FormControl
                                                  my={2}
                                                  isInvalid={
                                                    get(form, [
                                                      'errors',
                                                      'techniques',
                                                      index,
                                                      'description',
                                                    ]) &&
                                                    get(form, [
                                                      'touched',
                                                      'techniques',
                                                      index,
                                                      'description',
                                                    ])
                                                  }
                                                >
                                                  <Input
                                                    {...field}
                                                    id={`techniques.${index}.description`}
                                                    placeholder="Digitar Técnica"
                                                  />
                                                  <FormErrorMessage>
                                                    {get(form, [
                                                      'errors',
                                                      'techniques',
                                                      index,
                                                      'description',
                                                    ])}
                                                  </FormErrorMessage>
                                                </FormControl>
                                              )}
                                            </Field>
                                          </div>
                                          <Flex
                                            alignItems="center"
                                            justify="center"
                                          >
                                            <Button
                                              type="button"
                                              onClick={() => remove(index)}
                                              colorScheme="white"
                                              variant="outline"
                                            >
                                              <DeleteIcon m="0" />
                                            </Button>
                                          </Flex>
                                        </SimpleGrid>
                                      )
                                    )}
                                  <Button
                                    mt="5"
                                    type="button"
                                    className="secondary"
                                    onClick={() => {
                                      push({ description: '' });
                                    }}
                                  >
                                    Agregar Técnica
                                  </Button>
                                </div>
                              )}
                            </FieldArray>
                          </Box>
                        </TabPanel>
                        <TabPanel>
                          <Box>
                            <FieldArray name="utensils">
                              {({ remove, push }) => (
                                <div>
                                  {props.values.utensils &&
                                    props.values.utensils.length > 0 &&
                                    props.values.utensils.map(
                                      (techinique, index) => (
                                        <SimpleGrid
                                          columns={2}
                                          spacing={5}
                                          key={index}
                                        >
                                          <div className="col">
                                            <Field
                                              name={`utensils.${index}.description`}
                                              validate={validateRequired}
                                            >
                                              {({ field, form }) => (
                                                <FormControl
                                                  my={2}
                                                  isInvalid={
                                                    get(form, [
                                                      'errors',
                                                      'utensils',
                                                      index,
                                                      'description',
                                                    ]) &&
                                                    get(form, [
                                                      'touched',
                                                      'utensils',
                                                      index,
                                                      'description',
                                                    ])
                                                  }
                                                >
                                                  <Input
                                                    {...field}
                                                    id={`utensils.${index}.description`}
                                                    placeholder="Digitar Menaje"
                                                  />
                                                  <FormErrorMessage>
                                                    {get(form, [
                                                      'errors',
                                                      'utensils',
                                                      index,
                                                      'description',
                                                    ])}
                                                  </FormErrorMessage>
                                                </FormControl>
                                              )}
                                            </Field>
                                          </div>
                                          <Flex
                                            alignItems="center"
                                            justify="center"
                                          >
                                            <Button
                                              type="button"
                                              onClick={() => remove(index)}
                                              colorScheme="white"
                                              variant="outline"
                                            >
                                              <DeleteIcon m="0" />
                                            </Button>
                                          </Flex>
                                        </SimpleGrid>
                                      )
                                    )}
                                  <Button
                                    mt="5"
                                    type="button"
                                    className="secondary"
                                    onClick={() => {
                                      push({ description: '' });
                                    }}
                                  >
                                    Agregar Menaje
                                  </Button>
                                </div>
                              )}
                            </FieldArray>
                          </Box>
                        </TabPanel>
                        <TabPanel>
                          <Box>
                            <FieldArray name="categories">
                              {({ remove, push }) => (
                                <div>
                                  {props.values.categories &&
                                    props.values.categories.length > 0 &&
                                    props.values.categories.map(
                                      (cat, index) => (
                                        <SimpleGrid
                                          columns={2}
                                          spacing={5}
                                          key={index}
                                        >
                                          <div className="col">
                                            <Field
                                              name={`categories.${index}.name`}
                                              validate={validateRequired}
                                            >
                                              {({ field, form }) => (
                                                <FormControl
                                                  my={2}
                                                  isInvalid={
                                                    get(form, [
                                                      'errors',
                                                      'categories',
                                                      index,
                                                      'name',
                                                    ]) &&
                                                    get(form, [
                                                      'touched',
                                                      'categories',
                                                      index,
                                                      'name',
                                                    ])
                                                  }
                                                >
                                                  {/* <Input {...field}  placeholder="Digitar Categoría" /> */}
                                                  <ItemSearch
                                                    {...field}
                                                    placeholder="Buscar Categoría"
                                                    indexName="categories"
                                                  />
                                                  <FormErrorMessage>
                                                    {get(form, [
                                                      'errors',
                                                      'categories',
                                                      index,
                                                      'name',
                                                    ])}
                                                  </FormErrorMessage>
                                                </FormControl>
                                              )}
                                            </Field>
                                          </div>
                                          <Flex
                                            alignItems="center"
                                            justify="center"
                                          >
                                            <Button
                                              type="button"
                                              onClick={() => remove(index)}
                                              colorScheme="white"
                                              variant="outline"
                                            >
                                              <DeleteIcon m="0" />
                                            </Button>
                                          </Flex>
                                        </SimpleGrid>
                                      )
                                    )}
                                  <Button
                                    mt="5"
                                    type="button"
                                    className="secondary"
                                    disabled={
                                      props.values.categories.length >= 5
                                    }
                                    onClick={() => {
                                      push({ name: '' });
                                    }}
                                  >
                                    Agregar Categoría
                                  </Button>
                                </div>
                              )}
                            </FieldArray>
                          </Box>
                        </TabPanel>
                        <TabPanel>
                          <Box>
                            <FieldArray name="recipeSteps">
                              {({ remove, push }) => (
                                <div>
                                  {props.values.recipeSteps.length > 0 &&
                                    props.values.recipeSteps.map(
                                      (cat, index) => (
                                        <SimpleGrid
                                          columns={5}
                                          spacing={2}
                                          key={index}
                                        >
                                          <Box
                                            gridColumn="span 3"
                                            className="col"
                                          >
                                            <Field
                                              name={`recipeSteps.${index}.description`}
                                              validate={validateRequired}
                                            >
                                              {({ field, form }) => (
                                                <FormControl
                                                  my={2}
                                                  isInvalid={
                                                    get(form, [
                                                      'errors',
                                                      'recipeSteps',
                                                      index,
                                                      'description',
                                                    ]) &&
                                                    get(form, [
                                                      'touched',
                                                      'recipeSteps',
                                                      index,
                                                      'description',
                                                    ])
                                                  }
                                                >
                                                  <Input
                                                    {...field}
                                                    placeholder="Agregue la descripción de este paso"
                                                  />
                                                  <FormErrorMessage>
                                                    {get(form, [
                                                      'errors',
                                                      'recipeSteps',
                                                      index,
                                                      'description',
                                                    ])}
                                                  </FormErrorMessage>
                                                </FormControl>
                                              )}
                                            </Field>
                                          </Box>
                                          <Flex
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Field
                                              name={`recipeSteps.${index}.isDivider`}
                                            >
                                              {({ field, form }) => (
                                                <FormControl
                                                  my={2}
                                                  display="flex"
                                                  justifyContent="center"
                                                  alignItems="center"
                                                >
                                                  <Checkbox
                                                    {...field}
                                                    isChecked={field.value}
                                                  >
                                                    Separador
                                                  </Checkbox>
                                                </FormControl>
                                              )}
                                            </Field>
                                          </Flex>
                                          <Flex
                                            alignItems="center"
                                            justify="center"
                                            w="100px"
                                          >
                                            <Button
                                              type="button"
                                              onClick={() => remove(index)}
                                              colorScheme="white"
                                              variant="outline"
                                            >
                                              <DeleteIcon m="0" />
                                            </Button>
                                          </Flex>
                                        </SimpleGrid>
                                      )
                                    )}
                                  <Button
                                    mt="5"
                                    type="button"
                                    className="secondary"
                                    onClick={() => {
                                      push({
                                        description: '',
                                        isDivider: false,
                                      });
                                    }}
                                  >
                                    Agregar Paso
                                  </Button>
                                </div>
                              )}
                            </FieldArray>
                          </Box>
                        </TabPanel>
                        <TabPanel>
                          <Box w="600px">
                            <SimpleGrid columns={4} spacing={2}>
                              <Field name="nutritional.energy.value">
                                {({ field, form }) => (
                                  <FormControl>
                                    <FormLabel htmlFor="nutritional.energy.value">
                                      {get(
                                        form,
                                        [
                                          'values',
                                          'nutritional',
                                          'energy',
                                          'description',
                                        ],
                                        'Energía'
                                      )}
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="nutritional.energy.value"
                                      type="number"
                                      isDisabled
                                    />
                                  </FormControl>
                                )}
                              </Field>
                              <Field name="nutritional.energy.unit">
                                {({ field, form }) => (
                                  <FormControl>
                                    <FormLabel htmlFor="nutritional.energy.unit">
                                      Unidad
                                    </FormLabel>
                                    <Input
                                      w="100px"
                                      fontStyle="italic"
                                      float="left"
                                      {...field}
                                      id="nutritional.energy.unit"
                                      isDisabled
                                    />
                                  </FormControl>
                                )}
                              </Field>
                              <Field name="nutritional.carbohydrates.value">
                                {({ field, form }) => (
                                  <FormControl>
                                    <FormLabel htmlFor="nutritional.carbohydrates.value">
                                      {get(
                                        form,
                                        [
                                          'values',
                                          'nutritional',
                                          'carbohydrates',
                                          'description',
                                        ],
                                        'Carbohidratos'
                                      )}
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="nutritional.carbohydrates.value"
                                      type="number"
                                      isDisabled
                                    />
                                  </FormControl>
                                )}
                              </Field>
                              <Field name="nutritional.carbohydrates.unit">
                                {({ field, form }) => (
                                  <FormControl>
                                    <FormLabel htmlFor="nutritional.carbohydrates.unit">
                                      Unidad
                                    </FormLabel>
                                    <Input
                                      w="100px"
                                      fontStyle="italic"
                                      float="left"
                                      {...field}
                                      id="nutritional.carbohydrates.unit"
                                      isDisabled
                                    />
                                  </FormControl>
                                )}
                              </Field>
                              <Field name="nutritional.protein.value">
                                {({ field, form }) => (
                                  <FormControl>
                                    <FormLabel htmlFor="nutritional.protein.value">
                                      {get(
                                        form,
                                        [
                                          'values',
                                          'nutritional',
                                          'protein',
                                          'description',
                                        ],
                                        'Proteina'
                                      )}
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="nutritional.protein.value"
                                      type="number"
                                      isDisabled
                                    />
                                  </FormControl>
                                )}
                              </Field>
                              <Field name="nutritional.protein.unit">
                                {({ field, form }) => (
                                  <FormControl>
                                    <FormLabel htmlFor="nutritional.protein.unit">
                                      Unidad
                                    </FormLabel>
                                    <Input
                                      w="100px"
                                      fontStyle="italic"
                                      float="left"
                                      {...field}
                                      id="nutritional.protein.unit"
                                      isDisabled
                                    />
                                  </FormControl>
                                )}
                              </Field>
                              <Field name="nutritional.fiber.value">
                                {({ field, form }) => (
                                  <FormControl>
                                    <FormLabel htmlFor="nutritional.fiber.value">
                                      {get(
                                        form,
                                        [
                                          'values',
                                          'nutritional',
                                          'fiber',
                                          'description',
                                        ],
                                        'Fibra'
                                      )}
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="nutritional.fiber.value"
                                      type="number"
                                      isDisabled
                                    />
                                  </FormControl>
                                )}
                              </Field>
                              <Field name="nutritional.fiber.unit">
                                {({ field, form }) => (
                                  <FormControl>
                                    <FormLabel htmlFor="nutritional.fiber.unit">
                                      Unidad
                                    </FormLabel>
                                    <Input
                                      w="100px"
                                      fontStyle="italic"
                                      float="left"
                                      {...field}
                                      id="nutritional.fiber.unit"
                                      isDisabled
                                    />
                                  </FormControl>
                                )}
                              </Field>
                              <Field name="nutritional.fat.value">
                                {({ field, form }) => (
                                  <FormControl>
                                    <FormLabel htmlFor="nutritional.fat.value">
                                      {get(
                                        form,
                                        [
                                          'values',
                                          'nutritional',
                                          'fat',
                                          'description',
                                        ],
                                        'Grasa'
                                      )}
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="nutritional.fat.value"
                                      type="number"
                                      isDisabled
                                    />
                                  </FormControl>
                                )}
                              </Field>
                              <Field name="nutritional.fat.unit">
                                {({ field, form }) => (
                                  <FormControl>
                                    <FormLabel htmlFor="nutritional.fat.unit">
                                      Unidad
                                    </FormLabel>
                                    <Input
                                      w="100px"
                                      fontStyle="italic"
                                      float="left"
                                      {...field}
                                      id="nutritional.fat.unit"
                                      isDisabled
                                    />
                                  </FormControl>
                                )}
                              </Field>
                              <Field name="nutritional.cholesterol.value">
                                {({ field, form }) => (
                                  <FormControl>
                                    <FormLabel htmlFor="nutritional.cholesterol.value">
                                      {get(
                                        form,
                                        [
                                          'values',
                                          'nutritional',
                                          'cholesterol',
                                          'description',
                                        ],
                                        'Colesterol'
                                      )}
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="nutritional.cholesterol.value"
                                      type="number"
                                      isDisabled
                                    />
                                  </FormControl>
                                )}
                              </Field>
                              <Field name="nutritional.cholesterol.unit">
                                {({ field, form }) => (
                                  <FormControl>
                                    <FormLabel htmlFor="nutritional.cholesterol.unit">
                                      Unidad
                                    </FormLabel>
                                    <Input
                                      w="100px"
                                      fontStyle="italic"
                                      float="left"
                                      {...field}
                                      id="nutritional.cholesterol.unit"
                                      isDisabled
                                    />
                                  </FormControl>
                                )}
                              </Field>
                              <Field name="nutritional.sodium.value">
                                {({ field, form }) => (
                                  <FormControl>
                                    <FormLabel htmlFor="nutritional.sodium.value">
                                      {get(
                                        form,
                                        [
                                          'values',
                                          'nutritional',
                                          'sodium',
                                          'description',
                                        ],
                                        'Sodio'
                                      )}
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="nutritional.sodium.value"
                                      type="number"
                                      isDisabled
                                    />
                                  </FormControl>
                                )}
                              </Field>
                              <Field name="nutritional.sodium.unit">
                                {({ field, form }) => (
                                  <FormControl>
                                    <FormLabel htmlFor="nutritional.sodium.unit">
                                      Unidad
                                    </FormLabel>
                                    <Input
                                      w="100px"
                                      fontStyle="italic"
                                      float="left"
                                      {...field}
                                      id="nutritional.sodium.unit"
                                      isDisabled
                                    />
                                  </FormControl>
                                )}
                              </Field>
                              <Button mt="8" onClick={onOpen}>
                                Generar
                              </Button>
                              <Box gridColumn="span 4">
                                <Field name="nutritional.observations">
                                  {({ field, form }) => (
                                    <FormControl>
                                      <FormLabel htmlFor="nutritional.observations">
                                        Observaciones
                                      </FormLabel>
                                      <Input
                                        {...field}
                                        id="nutritional.observations"
                                      />
                                    </FormControl>
                                  )}
                                </Field>
                              </Box>
                            </SimpleGrid>
                          </Box>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                    <CompositionTable
                      isOpen={isOpen}
                      onOpen={onOpen}
                      onClose={onClose}
                      currentRecipeId={currentRecipeId}
                      recipe={props.values}
                    />
                    <Button
                      mt={4}
                      color="brand.corona"
                      isLoading={props.isSubmitting}
                      type="submit"
                    >
                      Guardar
                    </Button>
                  </Form>
                )}
              </Formik>
            </TabPanel>
            <TabPanel px="0">
              <Summary recipe={currentRecipe} recipeId={currentRecipeId} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
};

EditRecipe.propTypes = {
  firebase: PropTypes.shape().isRequired,
};

const condition = authUser => authUser;
const ComposedEditRecipe = compose(
  withFirebase,
  withAuthorization(condition)
)(EditRecipe);
export default ComposedEditRecipe;
