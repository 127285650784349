import React from 'react';
import { compose } from "recompose"
import {
  ChakraProvider,
  Box,
} from '@chakra-ui/react';
import { ToastProvider } from 'react-toast-notifications';
import theme from './utils/theme';
import { withAuthentication } from "./context/Session"
import Routes from './components/organisms/Routes';
import "./App.css"

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <ToastProvider autoDismiss>
        <Box textAlign="center" fontSize="xl" bgColor="brand.black">
          <Routes />
        </Box>
      </ToastProvider>
    </ChakraProvider>
  );
}

const RootApp = compose(withAuthentication)(App)
export default RootApp


// import React from "react"
// import { ThemeProvider } from "styled-components"
// import { compose } from "recompose"
// import { ToastProvider } from "react-toast-notifications"
// import Routes from "./components/molecules/Routes"
// import { withTrans } from "./i18n/withTrans"
// import { withAuthentication } from "./context/Session"
// import ChatContext from "./context/Chat"
// import { chatReducer, initialChatState } from "./context/Chat/reducer"
// // import "./static/fonts/fonts.css"
// // import GlobalStyle from "./styles/GlobalStyles"
// import theme from "./styles/theme"
// import "./App.css"

// const App = () => {
//   const [state, dispatch] = React.useReducer(chatReducer, initialChatState)
//   return (
//     <ThemeProvider theme={theme}>
//       <>
//         <GlobalStyle />
//         <div className="App">
//           <ChatContext.Provider value={{ state, dispatch }}>
//             <ToastProvider autoDismiss>
//               <Routes />
//             </ToastProvider>
//           </ChatContext.Provider>
//         </div>
//       </>
//     </ThemeProvider>
//   )
// }

// const RootApp = compose(withTrans, withAuthentication)(App)
// export default RootApp
