import React, { useState } from "react"
import { compose } from "recompose"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import {
  Flex,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
} from "@chakra-ui/react"

import Logo from "../../atoms/Logo"
import ErrorMessage from "../../atoms/ErrorMessage"
import { withFirebase } from "../../../utils/Firebase"
import * as ROUTES from "../../../utils/routes"

const LoginFormBase = ({ firebase, history }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [isLogginIn, setIsLogginIn] = useState(false)
  const handleSubmit = (event) => {
    event.preventDefault()
    setIsLogginIn(true)
    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        setIsLogginIn(false)
        history.push(ROUTES.RECIPES)
      })
      .catch((err) => {
        // alert(`Error: ${err}`)
        setError(err.message)
        // this.setState({ error: err, isLogginIn: false })
        setIsLogginIn(false)
      })
  }
  return (
    <Flex
      direction="column"
      width="full"
      heigh="100%"
      align="center"
      justifyContent="center"
      my="2"
    >
      <Flex justify="center" width="100%" my="5">
        <Logo h={['50vmin', '30vmin']} pointerEvents="none" p={10}/>
      </Flex>
      <Box
        p={8}
        w={[300, 500]}
        borderWidth={1}
        borderRadius={8}
        boxShadow="lg"
      >
        <Box textAlign="center">
          <Heading textStyle="h1">Iniciar Sesión</Heading>
        </Box>
        <Box my={4} textAlign="left" >
          <form onSubmit={handleSubmit}>
            <FormControl isRequired>
              <FormLabel textStyle="h1">Usuario</FormLabel>
              <Input
                color='brand.white'
                defaultValue={email}
                type="email"
                placeholder="Ingrese su correo electrónico"
                onChange={(event) => setEmail(event.currentTarget.value)}
              />
            </FormControl>
            <FormControl mt={6} isRequired>
              <FormLabel textStyle="h1">Contraseña</FormLabel>
              <Input
                color='brand.white'
                defaultValue={password}
                type="password"
                placeholder="*******"
                onChange={(event) => setPassword(event.currentTarget.value)}
              />
            </FormControl>
            <Button type="submit" width="full" mt={4} disabled={isLogginIn}>
              Iniciar Sesión
            </Button>
            {error && <ErrorMessage message={error} />}
          </form>
        </Box>
      </Box>
    </Flex>
  )
}

LoginFormBase.propTypes = {
  firebase: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
}

const LoginForm = compose(withRouter, withFirebase)(LoginFormBase)
export default LoginForm
