import React, { Component } from 'react';
import { Input } from "@chakra-ui/react"

class DebouncedInput extends Component {
  timerId = null;

  state = {
    value: this.props.value
  };

  onChangeDebounced = event => {
    const { onChange, delay } = this.props;
    const value = event.currentTarget.value;

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => onChange(event), delay);

    this.setState(() => ({
      value
    }));
  };

  render() {
    const { value } = this.state;

    return (
      <Input
      {...this.props}
      onChange={this.onChangeDebounced} value={value} />
      
    );
  }
}

export default DebouncedInput