import React from 'react';
import { compose } from 'recompose';
import PropTypes, { object } from 'prop-types';
import get from 'lodash.get';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  SimpleGrid,
  Button,
  Input,
  FormControl,
  FormLabel,
  Box,
  Divider,
  InputLeftAddon,
  InputGroup,
  Radio,
  RadioGroup,
  Stack,
  Flex,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spinner,
} from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';
import { Formik, Form, Field, FieldArray } from 'formik';
import { withFirebase } from '../../../utils/Firebase';
import { withAuthorization } from '../../../context/Session';
import ItemSearch from '../../molecules/ItemSearch';

const CompositionTable = ({
  isOpen,
  onOpen,
  onClose,
  recipe,
  currentRecipeId,
  firebase,
}) => {
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = React.useState(true);
  const [currentComposition, setCurrentComposition] = React.useState(null);

  React.useEffect(() => {
    if (get(recipe, 'ingredients') && isOpen) {
      let newComposition = {};
      Object.assign(newComposition, get(recipe, 'composition', {}))
      Object.keys(recipe.ingredients).forEach(k => {
        if (!get(recipe, ['composition', k, 'carbos'])) {
          newComposition[k] = {
            ...newComposition[k],
            carbos: 0
          };
        }
        if (!get(recipe, ['composition', k, 'colesterol'])) {
          newComposition[k] = {
            ...newComposition[k],
            colesterol: 0
          };
        }
        if (!get(recipe, ['composition', k, 'grasa'])) {
          newComposition[k] = {
            ...newComposition[k],
            grasa: 0
          };
        }
        if (!get(recipe, ['composition', k, 'protein'])) {
          newComposition[k] = {
            ...newComposition[k],
            protein: 0
          };
        }
        if (!get(recipe, ['composition', k, 'sodium'])) {
          newComposition[k] = {
            ...newComposition[k],
            sodium: 0
          };
        }
        if (!get(recipe, ['composition', k, 'kcal'])) {
          newComposition[k] = {
            ...newComposition[k],
            kcal: 0
          };
        }
        if (!get(recipe, ['composition', k, 'fiber'])) {
          newComposition[k] = {
            ...newComposition[k],
            fiber: 0
          };
        }
      });
      setCurrentComposition(newComposition);
      setIsLoading(false);
    }
  }, [recipe, isOpen]);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          ...recipe,
          composition: currentComposition, // get(recipe, 'composition', {}),
        }}
        onSubmit={async (values, actions) => {
          const currentValues = values;
          try {
            let mappedNutritional = {
              carbohydrates: {
                value: 0,
              },
              cholesterol: {
                value: 0,
              },
              energy: {
                value: 0,
              },
              fat: {
                value: 0,
              },
              fiber: {
                value: 0,
              },
              protein: {
                value: 0,
              },
              sodium: {
                value: 0,
              },
            };
            const authToken = await firebase.getIdToken();
            const valuesComp = Object.values(values.composition);
            mappedNutritional.carbohydrates.value = valuesComp.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.carbosValue || 0,
              0
            );
            mappedNutritional.cholesterol.value = valuesComp.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.colesterolValue || 0,
              0
            );
            mappedNutritional.energy.value = valuesComp.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.kcalValue || 0,
              0
            );
            mappedNutritional.fat.value = valuesComp.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.grasaValue || 0,
              0
            );
            mappedNutritional.fiber.value = valuesComp.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.fiberValue || 0,
              0
            );
            mappedNutritional.protein.value = valuesComp.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.proteinValue || 0,
              0
            );
            mappedNutritional.sodium.value = valuesComp.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.sodiumValue || 0,
              0
            );
            Object.keys(mappedNutritional).forEach(key => {
              currentValues.nutritional[key].value =
                mappedNutritional[key].value;
            });
            const createdRecipe = await fetch(
              'https://us-central1-chef-fran.cloudfunctions.net/recipesUpdate',
              {
                method: 'POST',
                body: JSON.stringify(
                  {
                    recipe: currentValues,
                    recipeId: currentRecipeId,
                  },
                  null,
                  2
                ),
                headers: {
                  Authorization: `Bearer ${authToken}`,
                  'Content-Type': 'application/json',
                },
              }
            );
            const data = await createdRecipe.json();
            if (data.error) {
              addToast('Ocurrio un error inesperado, intenta nuevamente', {
                appearance: 'error',
              });
            } else {
              onClose();
            }
            actions.setSubmitting(false);
          } catch (error) {
            addToast('Ocurrio un error inesperado, intenta nuevamente', {
              appearance: 'error',
            });
            actions.setSubmitting(false);
          }
        }}
      >
        {props => (
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            motionPreset="slideInBottom"
            size="5xl"
          >
            <ModalOverlay />
            {isLoading && (
              <ModalContent>
                <ModalBody minH="60vh" maxH="60vh" overflowY="auto">
                  <Flex minH="60vh" justifyContent="center" alignItems="center">
                    <Spinner />
                  </Flex>
                </ModalBody>
              </ModalContent>
            )}
            {!isLoading && (
              <ModalContent>
                <ModalHeader>Generar Información Nutricional</ModalHeader>
                <ModalCloseButton />
                <ModalBody minH="60vh" maxH="60vh" overflowY="auto">
                  <Form noValidate={true}>
                    <FieldArray name="ingredients">
                      {({ remove, push }) => (
                        <div>
                          {props.values.ingredients.map((i, index) => {
                            return (
                              <SimpleGrid columns={5} spacing={2}>
                                <Box>
                                  <FormControl>
                                    <FormLabel
                                      htmlFor={`ingredients.${index}.name`}
                                    >
                                      Ingrediente
                                    </FormLabel>
                                    <Input
                                      color="brand.corona"
                                      fontWeight="bold"
                                      fontStyle="italic"
                                      value={i.name}
                                      isReadOnly={true}
                                    />
                                  </FormControl>
                                </Box>
                                <Flex alignItems="flex-end" pb="10px" w="170px">
                                  <Field name={`composition.${index}.type`}>
                                    {({ field, form }) => (
                                      <RadioGroup
                                        {...field}
                                        id={`composition.${index}.type`}
                                        defaultValue="table"
                                      >
                                        <Stack direction="row">
                                          <Radio {...field} value="table">
                                            Normal
                                          </Radio>
                                          <Radio {...field} value="label">
                                            Etiqueta
                                          </Radio>
                                        </Stack>
                                      </RadioGroup>
                                    )}
                                  </Field>
                                </Flex>
                                <Box gridColumn="span 2">
                                  <Field name={`composition.${index}.name`}>
                                    {({ field, form }) => (
                                      <FormControl>
                                        <FormLabel
                                          htmlFor={`composition.${index}.name`}
                                        >
                                          Equivalente
                                        </FormLabel>
                                        <>
                                          {get(
                                            props,
                                            [
                                              'values',
                                              'composition',
                                              index,
                                              'type',
                                            ],
                                            'table'
                                          ) === 'table' && (
                                            <ItemSearch
                                              {...field}
                                              customHitsPerPage={8}
                                              onSelectedProduct={f => {
                                                form.setFieldValue(
                                                  `ingredients.${index}.equivalentId`,
                                                  f.id
                                                );
                                                form.setFieldValue(
                                                  `ingredients.${index}.equivalentName`,
                                                  f.name
                                                );
                                                form.setFieldValue(
                                                  `composition.${index}.equivalentId`,
                                                  f.id
                                                );
                                                form.setFieldValue(
                                                  `composition.${index}.sodium`,
                                                  parseFloat(
                                                    f['Sodio (mg)']
                                                      ?.toString()
                                                      .replace(',', '.')
                                                  )
                                                );
                                                form.setFieldValue(
                                                  `composition.${index}.sodiumValue`,
                                                  (i.weight *
                                                    parseFloat(
                                                      f['Sodio (mg)']
                                                        ?.toString()
                                                        .replace(',', '.')
                                                    )) /
                                                    100
                                                );

                                                form.setFieldValue(
                                                  `composition.${index}.grasa`,
                                                  f['Grasa Total (g)']
                                                    ?.toString()
                                                    .replace(',', '.')
                                                );
                                                form.setFieldValue(
                                                  `composition.${index}.grasaValue`,
                                                  (i.weight *
                                                    parseFloat(
                                                      f['Grasa Total (g)']
                                                        ?.toString()
                                                        .replace(',', '.')
                                                    )) /
                                                    100
                                                );

                                                form.setFieldValue(
                                                  `composition.${index}.protein`,
                                                  f['Proteina (g)']
                                                    ?.toString()
                                                    .replace(',', '.')
                                                );
                                                form.setFieldValue(
                                                  `composition.${index}.proteinValue`,
                                                  (i.weight *
                                                    parseFloat(
                                                      f['Proteina (g)']
                                                        ?.toString()
                                                        .replace(',', '.')
                                                    )) /
                                                    100
                                                );

                                                form.setFieldValue(
                                                  `composition.${index}.kcal`,
                                                  f['Energía(Kcal)']
                                                    ?.toString()
                                                    .replace(',', '.')
                                                );
                                                form.setFieldValue(
                                                  `composition.${index}.kcalValue`,
                                                  (i.weight *
                                                    parseFloat(
                                                      f['Energía(Kcal)']
                                                        ?.toString()
                                                        .replace(',', '.')
                                                    )) /
                                                    100
                                                );

                                                form.setFieldValue(
                                                  `composition.${index}.carbos`,
                                                  f['Carbohidratos (g)']
                                                    ?.toString()
                                                    .replace(',', '.')
                                                );
                                                form.setFieldValue(
                                                  `composition.${index}.carbosValue`,
                                                  (i.weight *
                                                    parseFloat(
                                                      f['Carbohidratos (g)']
                                                        ?.toString()
                                                        .replace(',', '.')
                                                    )) /
                                                    100
                                                );

                                                form.setFieldValue(
                                                  `composition.${index}.colesterol`,
                                                  f['Colesterol (mg)']
                                                    ?.toString()
                                                    .replace(',', '.')
                                                );
                                                form.setFieldValue(
                                                  `composition.${index}.colesterolValue`,
                                                  (i.weight *
                                                    parseFloat(
                                                      f['Colesterol (mg)']
                                                        ?.toString()
                                                        .replace(',', '.')
                                                    )) /
                                                    100
                                                );
                                              }}
                                              placeholder="Buscar Equivalente"
                                              indexName="composition"
                                            />
                                          )}
                                          {get(
                                            props,
                                            [
                                              'values',
                                              'composition',
                                              index,
                                              'type',
                                            ],
                                            ''
                                          ) === 'label' && (
                                            <Input
                                              isDisabled
                                              placeholder="Digitar los valores requeridos"
                                            />
                                          )}
                                        </>
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                                <Box>
                                  <FormControl>
                                    <FormLabel>Peso</FormLabel>
                                    <Input
                                      isDisabled
                                      value={i.weight}
                                      isReadOnly={true}
                                    />
                                  </FormControl>
                                </Box>
                                <Box gridColumn="span 5">
                                  <SimpleGrid columns={6} spacing={4}>
                                    <Box>
                                      <FormControl>
                                        <FormLabel
                                          htmlFor={`composition.${index}.kcal`}
                                        >
                                          Kcal
                                        </FormLabel>
                                        <InputGroup>
                                          {get(
                                            props,
                                            [
                                              'values',
                                              'composition',
                                              index,
                                              'type',
                                            ],
                                            'table'
                                          ) === 'table' && (
                                            <InputLeftAddon
                                              children={get(
                                                props,
                                                [
                                                  'values',
                                                  'composition',
                                                  index,
                                                  'kcal',
                                                ],
                                                0
                                              )}
                                              fontSize="xs"
                                            />
                                          )}
                                          {get(
                                            props,
                                            [
                                              'values',
                                              'composition',
                                              index,
                                              'type',
                                            ],
                                            ''
                                          ) === 'label' && (
                                            <Field
                                              name={`composition.${index}.kcal`}
                                            >
                                              {({ field, form }) => (
                                                <NumberInput
                                                  {...field}
                                                  id={`composition.${index}.kcal`}
                                                  min={1}
                                                  precision={2}
                                                  step={0.2}
                                                  minW="80px"
                                                  value={get(
                                                    field,
                                                    'value',
                                                    '1'
                                                  )}
                                                  onChange={(e, t) => {
                                                    form.setFieldValue(
                                                      `composition.${index}.kcal`,
                                                      isNaN(t) ? 0 : t
                                                    );
                                                    form.setFieldValue(
                                                      `composition.${index}.kcalValue`,
                                                      t > 0 ? (i.weight * t) / 100 : 0
                                                    );
                                                  }}
                                                >
                                                  <NumberInputField
                                                    padding="0px"
                                                    pl="5px"
                                                    fontSize="xs"
                                                  />
                                                  <NumberInputStepper>
                                                    <NumberIncrementStepper />
                                                    <NumberDecrementStepper />
                                                  </NumberInputStepper>
                                                </NumberInput>
                                              )}
                                            </Field>
                                          )}
                                          <Input
                                            value={get(
                                              props,
                                              [
                                                'values',
                                                'composition',
                                                index,
                                                'kcalValue',
                                              ],
                                              0
                                            )}
                                            id={`composition.${index}.kcal`}
                                            placeholder="0"
                                            fontSize="xs"
                                          />
                                        </InputGroup>
                                      </FormControl>
                                    </Box>
                                    <Box>
                                      <FormControl>
                                        <FormLabel
                                          htmlFor={`composition.${index}.protein`}
                                        >
                                          Proteina
                                        </FormLabel>
                                        <InputGroup>
                                          {get(
                                            props,
                                            [
                                              'values',
                                              'composition',
                                              index,
                                              'type',
                                            ],
                                            'table'
                                          ) === 'table' && (
                                            <InputLeftAddon
                                              children={get(
                                                props,
                                                [
                                                  'values',
                                                  'composition',
                                                  index,
                                                  'protein',
                                                ],
                                                0
                                              )}
                                              fontSize="xs"
                                            />
                                          )}
                                          {get(
                                            props,
                                            [
                                              'values',
                                              'composition',
                                              index,
                                              'type',
                                            ],
                                            ''
                                          ) === 'label' && (
                                            <Field
                                              name={`composition.${index}.protein`}
                                            >
                                              {({ field, form }) => (
                                                <NumberInput
                                                  {...field}
                                                  id={`composition.${index}.protein`}
                                                  min={1}
                                                  precision={2}
                                                  step={0.2}
                                                  minW="80px"
                                                  value={get(
                                                    field,
                                                    'value',
                                                    '1'
                                                  )}
                                                  onChange={(e, t) => {
                                                    form.setFieldValue(
                                                      `composition.${index}.protein`,
                                                      isNaN(t) ? 0 : t
                                                    );
                                                    form.setFieldValue(
                                                      `composition.${index}.proteinValue`,
                                                      t > 0 ? (i.weight * t) / 100 : 0
                                                    );
                                                  }}
                                                >
                                                  <NumberInputField
                                                    padding="0px"
                                                    pl="5px"
                                                    fontSize="xs"
                                                  />
                                                  <NumberInputStepper>
                                                    <NumberIncrementStepper />
                                                    <NumberDecrementStepper />
                                                  </NumberInputStepper>
                                                </NumberInput>
                                              )}
                                            </Field>
                                          )}
                                          <Input
                                            value={get(
                                              props,
                                              [
                                                'values',
                                                'composition',
                                                index,
                                                'proteinValue',
                                              ],
                                              0
                                            )}
                                            id={`composition.${index}.protein`}
                                            placeholder="0"
                                            fontSize="xs"
                                          />
                                        </InputGroup>
                                      </FormControl>
                                    </Box>
                                    <Box>
                                      <FormControl>
                                        <FormLabel
                                          htmlFor={`composition.${index}.carbosValue`}
                                        >
                                          Carbohidratos
                                        </FormLabel>
                                        <InputGroup>
                                          {get(
                                            props,
                                            [
                                              'values',
                                              'composition',
                                              index,
                                              'type',
                                            ],
                                            'table'
                                          ) === 'table' && (
                                            <InputLeftAddon
                                              children={get(
                                                props,
                                                [
                                                  'values',
                                                  'composition',
                                                  index,
                                                  'carbos',
                                                ],
                                                0
                                              )}
                                              fontSize="xs"
                                            />
                                          )}
                                          {get(
                                            props,
                                            [
                                              'values',
                                              'composition',
                                              index,
                                              'type',
                                            ],
                                            ''
                                          ) === 'label' && (
                                            <Field
                                              name={`composition.${index}.carbos`}
                                            >
                                              {({ field, form }) => (
                                                <NumberInput
                                                  {...field}
                                                  id={`composition.${index}.carbos`}
                                                  min={1}
                                                  precision={2}
                                                  step={0.2}
                                                  minW="80px"
                                                  value={get(
                                                    field,
                                                    'value',
                                                    '1'
                                                  )}
                                                  onChange={(e, t) => {
                                                    form.setFieldValue(
                                                      `composition.${index}.carbos`,
                                                      isNaN(t) ? 0 : t
                                                    );
                                                    form.setFieldValue(
                                                      `composition.${index}.carbosValue`,
                                                      t > 0 ? (i.weight * t) / 100 : 0
                                                    );
                                                  }}
                                                >
                                                  <NumberInputField
                                                    padding="0px"
                                                    pl="5px"
                                                    fontSize="xs"
                                                  />
                                                  <NumberInputStepper>
                                                    <NumberIncrementStepper />
                                                    <NumberDecrementStepper />
                                                  </NumberInputStepper>
                                                </NumberInput>
                                              )}
                                            </Field>
                                          )}
                                          <Input
                                            value={get(
                                              props,
                                              [
                                                'values',
                                                'composition',
                                                index,
                                                'carbosValue',
                                              ],
                                              0
                                            )}
                                            id={`composition.${index}.carbos`}
                                            placeholder="0"
                                            fontSize="xs"
                                          />
                                        </InputGroup>
                                      </FormControl>
                                    </Box>
                                    <Box>
                                      <FormControl>
                                        <FormLabel
                                          htmlFor={`composition.${index}.grasa`}
                                        >
                                          Grasta Total
                                        </FormLabel>
                                        <InputGroup>
                                          {get(
                                            props,
                                            [
                                              'values',
                                              'composition',
                                              index,
                                              'type',
                                            ],
                                            'table'
                                          ) === 'table' && (
                                            <InputLeftAddon
                                              children={get(
                                                props,
                                                [
                                                  'values',
                                                  'composition',
                                                  index,
                                                  'grasa',
                                                ],
                                                0
                                              )}
                                              fontSize="xs"
                                            />
                                          )}
                                          {get(
                                            props,
                                            [
                                              'values',
                                              'composition',
                                              index,
                                              'type',
                                            ],
                                            ''
                                          ) === 'label' && (
                                            <Field
                                              name={`composition.${index}.grasa`}
                                            >
                                              {({ field, form }) => (
                                                <NumberInput
                                                  {...field}
                                                  id={`composition.${index}.grasa`}
                                                  min={1}
                                                  precision={2}
                                                  step={0.2}
                                                  minW="80px"
                                                  value={get(
                                                    field,
                                                    'value',
                                                    '1'
                                                  )}
                                                  onChange={(e, t) => {
                                                    form.setFieldValue(
                                                      `composition.${index}.grasa`,
                                                      isNaN(t) ? 0 : t
                                                    );
                                                    form.setFieldValue(
                                                      `composition.${index}.grasaValue`,
                                                      t > 0 ? (i.weight * t) / 100 : 0
                                                    );
                                                  }}
                                                >
                                                  <NumberInputField
                                                    padding="0px"
                                                    pl="5px"
                                                    fontSize="xs"
                                                  />
                                                  <NumberInputStepper>
                                                    <NumberIncrementStepper />
                                                    <NumberDecrementStepper />
                                                  </NumberInputStepper>
                                                </NumberInput>
                                              )}
                                            </Field>
                                          )}
                                          <Input
                                            value={get(
                                              props,
                                              [
                                                'values',
                                                'composition',
                                                index,
                                                'grasaValue',
                                              ],
                                              0
                                            )}
                                            id={`composition.${index}.grasa`}
                                            placeholder="0"
                                            fontSize="xs"
                                          />
                                        </InputGroup>
                                      </FormControl>
                                    </Box>
                                    <Box>
                                      <FormControl>
                                        <FormLabel
                                          htmlFor={`composition.${index}.colesterol`}
                                        >
                                          Colesterol
                                        </FormLabel>
                                        <InputGroup>
                                          {get(
                                            props,
                                            [
                                              'values',
                                              'composition',
                                              index,
                                              'type',
                                            ],
                                            'table'
                                          ) === 'table' && (
                                            <InputLeftAddon
                                              children={get(
                                                props,
                                                [
                                                  'values',
                                                  'composition',
                                                  index,
                                                  'colesterol',
                                                ],
                                                0
                                              )}
                                              fontSize="xs"
                                            />
                                          )}
                                          {get(
                                            props,
                                            [
                                              'values',
                                              'composition',
                                              index,
                                              'type',
                                            ],
                                            ''
                                          ) === 'label' && (
                                            <Field
                                              name={`composition.${index}.colesterol`}
                                            >
                                              {({ field, form }) => (
                                                <NumberInput
                                                  {...field}
                                                  id={`composition.${index}.colesterol`}
                                                  min={1}
                                                  precision={2}
                                                  step={0.2}
                                                  minW="80px"
                                                  value={get(
                                                    field,
                                                    'value',
                                                    '1'
                                                  )}
                                                  onChange={(e, t) => {
                                                    form.setFieldValue(
                                                      `composition.${index}.colesterol`,
                                                      isNaN(t) ? 0 : t
                                                    );
                                                    form.setFieldValue(
                                                      `composition.${index}.colesterolValue`,
                                                      t > 0 ? (i.weight * t) / 100 : 0
                                                    );
                                                  }}
                                                >
                                                  <NumberInputField
                                                    padding="0px"
                                                    pl="5px"
                                                    fontSize="xs"
                                                  />
                                                  <NumberInputStepper>
                                                    <NumberIncrementStepper />
                                                    <NumberDecrementStepper />
                                                  </NumberInputStepper>
                                                </NumberInput>
                                              )}
                                            </Field>
                                          )}
                                          <Input
                                            value={get(
                                              props,
                                              [
                                                'values',
                                                'composition',
                                                index,
                                                'colesterolValue',
                                              ],
                                              0
                                            )}
                                            id={`composition.${index}.colesterol`}
                                            placeholder="0"
                                            fontSize="xs"
                                          />
                                        </InputGroup>
                                      </FormControl>
                                    </Box>
                                    <Box>
                                      <FormControl>
                                        <FormLabel
                                          htmlFor={`composition.${index}.sodium`}
                                        >
                                          Sodio
                                        </FormLabel>
                                        <InputGroup>
                                          {get(
                                            props,
                                            [
                                              'values',
                                              'composition',
                                              index,
                                              'type',
                                            ],
                                            'table'
                                          ) === 'table' && (
                                            <InputLeftAddon
                                              children={get(
                                                props,
                                                [
                                                  'values',
                                                  'composition',
                                                  index,
                                                  'sodium',
                                                ],
                                                0
                                              )}
                                              fontSize="xs"
                                            />
                                          )}
                                          {get(
                                            props,
                                            [
                                              'values',
                                              'composition',
                                              index,
                                              'type',
                                            ],
                                            ''
                                          ) === 'label' && (
                                            <Field
                                              name={`composition.${index}.sodium`}
                                            >
                                              {({ field, form }) => (
                                                <NumberInput
                                                  {...field}
                                                  id={`composition.${index}.sodium`}
                                                  min={1}
                                                  precision={2}
                                                  step={0.2}
                                                  minW="80px"
                                                  value={get(
                                                    field,
                                                    'value',
                                                    '1'
                                                  )}
                                                  onChange={(e, t) => {
                                                    form.setFieldValue(
                                                      `composition.${index}.sodium`,
                                                      isNaN(t) ? 0 : t
                                                    );
                                                    form.setFieldValue(
                                                      `composition.${index}.sodiumValue`,
                                                      t > 0 ? (i.weight * t) / 100 : 0
                                                    );
                                                  }}
                                                >
                                                  <NumberInputField
                                                    padding="0px"
                                                    pl="5px"
                                                    fontSize="xs"
                                                  />
                                                  <NumberInputStepper>
                                                    <NumberIncrementStepper />
                                                    <NumberDecrementStepper />
                                                  </NumberInputStepper>
                                                </NumberInput>
                                              )}
                                            </Field>
                                          )}
                                          <Input
                                            value={get(
                                              props,
                                              [
                                                'values',
                                                'composition',
                                                index,
                                                'sodiumValue',
                                              ],
                                              0
                                            )}
                                            id={`composition.${index}.sodiumValue`}
                                            placeholder="0"
                                            fontSize="xs"
                                          />
                                        </InputGroup>
                                      </FormControl>
                                    </Box>
                                  </SimpleGrid>
                                </Box>
                                <Divider
                                  gridColumn="span 4"
                                  borderBottomColor="brand.corona"
                                  mb="2"
                                />
                              </SimpleGrid>
                            );
                          })}
                        </div>
                      )}
                    </FieldArray>
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Cerrar
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={props.handleSubmit}
                    isLoading={props.isSubmitting}
                  >
                    Guardar
                  </Button>
                </ModalFooter>
              </ModalContent>
            )}
          </Modal>
        )}
      </Formik>
    </>
  );
};

CompositionTable.propTypes = {
  firebase: PropTypes.shape().isRequired,
};

const condition = authUser => authUser;
const ComposedCompositionTable = compose(
  withFirebase,
  withAuthorization(condition)
)(CompositionTable);

export default ComposedCompositionTable;
