import React from 'react';
import { connectRefinementList, Highlight } from 'react-instantsearch-dom';
import { Box, Checkbox, Stack, Text } from '@chakra-ui/react';

const CustomRefinementList = ({
  items,
  currentRefinement,
  refine,
  isFromSearch,
  searchForItems,
  isSearchable,
}) => {
  const currentItems = items.map(i => ({
    name: i.label === "food" ? "Comida" : "Bebidas",
    ...i
  }))
  return (
    <Box w="100%" maxW="lg" p="2">
      <Text fontWeight="bold" color="white" mb="10">Filtros</Text>
      <Stack spacing={3} direction="column">
        {isSearchable && (
          <div>
            <input
              type="search"
              onChange={event => searchForItems(event.currentTarget.value)}
            />
          </div>
        )}
        {currentItems.map(item => (
          <Checkbox
            key={item.label}
            onChange={event => {
              event.preventDefault();
              refine(item.value);
            }}
            style={{ fontWeight: item.isRefined ? 'bold' : '' }}
            isChecked={item.isRefined}
            color="brand.corona"
          >
            {' '}
            {isFromSearch ? (
              <Highlight attribute="name" hit={item} />
            ) : (
              item.name
            )}{' '}
            ({item.count})
          </Checkbox>
        ))}
      </Stack>
    </Box>
  );
  // return the DOM output
};

export default connectRefinementList(CustomRefinementList);
