import React from "react"
import { compose } from "recompose"
import PropTypes from "prop-types"
import { withFirebase } from "../../utils/Firebase"
import { withAuthorization } from "../../context/Session"

const Settings =() => {
  return (<div>
    <p>Settings</p>
  </div>)
}


Settings.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) => authUser
const ComposedSettings = compose(
  withFirebase,
  withAuthorization(condition)
)(Settings)
export default ComposedSettings
