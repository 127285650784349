import React from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom"
import Layout from '../Layout'
import Login from '../../../pages/Login'
import Recipes from '../../../pages/Recipes'
import NewRecipe from '../../../pages/Recipes/New'
import EditRecipe from '../../../pages/Recipes/Edit'
import GeneratePdf from "../../../pages/Recipes/GeneratePdf"
import Settings from '../../../pages/Settings'
import * as ROUTES from "../../../utils/routes"

function Routes () {
  return(
    <Router>
      <Route
        render={(routeProps) => (
          <Layout {...routeProps}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => {
                  // if (get(authUser, ["roles", "MARKETING"]))
                  //   return <Redirect to={ROUTES.MARKETING} />
                  return <Redirect to={ROUTES.RECIPES} />
                }}
              />
              <Route exact path={ROUTES.LOGIN}>
                <Login />
              </Route>
              <Route exact path={ROUTES.SETTINGS}>
                <Settings />
              </Route>
              <Route exact path={ROUTES.RECIPES}>
                <Recipes />
              </Route>
              <Route exact path={ROUTES.PDF_RECIPE}>
                <GeneratePdf />
              </Route>
              <Route exact path={ROUTES.EDIT_RECIPE}>
                <EditRecipe />
              </Route>
              <Route exact path={ROUTES.NEW_RECIPE}>
                <NewRecipe />
              </Route>
              
            </Switch>
          </Layout>
        )}
      />
    </Router>
  )
}

export default Routes