import React, { useState, useEffect } from "react"
import { connectHits } from 'react-instantsearch-dom';
import { Wrap, WrapItem } from "@chakra-ui/react"
import Recipe from '../../molecules/Recipe'

const Hits = ({ hits }) => {
  const [currentHits, setCurrentHits] = useState([])

  useEffect(() => {
    setCurrentHits(hits)
  }, [hits])

  const onRecipeDelete = (id) => {
    const filteredHits = currentHits.filter(h => h.objectID !== id)
    setCurrentHits(filteredHits)
  }

  return (
  <Wrap p="6" spacing="30px" align="center" justify="center" overflow="auto" h="calc(100vh - 140px)" mb="2">
    <WrapItem>
      <Recipe hit={{ new: true, name: "Crear Nueva Receta", imageUrl: "http://via.placeholder.com/800x550" }} />
    </WrapItem>
    {currentHits.map(hit => (
      <WrapItem key={hit.objectID}>
        <Recipe hit={hit} onRecipeDelete={onRecipeDelete} />
      </WrapItem>
    ))}
  </Wrap>
)};

const CustomHits = connectHits(Hits);
export default CustomHits