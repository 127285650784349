import React, {useEffect, useState} from 'react';
import { Box } from "@chakra-ui/react"
import { useDropzone } from 'react-dropzone';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginTop: 6
};

const thumb = {
  display: 'flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 140,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
  justifyContent: 'center'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


function Previews(props) {
  const [files, setFiles] = useState(props.currentImage ? [{ name: '', preview: props.currentImage}] : []);
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      props.setImage(acceptedFiles[0])
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });
  
  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
        />
      </div>
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <Box w="100%" borderWidth="1px" borderColor="gray.100" p={5} cursor="pointer">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p style={{ paddingTop: 10}}>{thumbs.length === 0 ? 'Seleccionar Foto' : 'Cambiar Foto' }</p>
      </div>
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
    </Box>
  );
}

export default Previews