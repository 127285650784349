import React from "react"
import { Flex, Text } from "@chakra-ui/react"

export default function Loading() {
  return (
    <Flex align="center" justify="center" bgColor="brand.background" minH="100vh" minW="100vw">
      <Text fontSize="6xl" color="white">Cargando...</Text>
    </Flex>
  )
}
