import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Box, Flex } from '@chakra-ui/react';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Configure,
  Pagination,
  ExperimentalDynamicWidgets,
} from 'react-instantsearch-dom';

import CustomHits from '../../components/organisms/CustomHits';
import DebouncedSearchBox from '../../components/atoms/DebouncedSearchBox';
import CustomRefinementList from '../../components/atoms/CustomRefinementList';
import { withFirebase } from '../../utils/Firebase';
import { withAuthorization } from '../../context/Session';

const Recipes = () => {
  const searchClient = algoliasearch(
    '76T3GSDQWX',
    'b4d4f4afe153636cea675592b321f806'
  );
  const search = searchClient.search;
  searchClient.search = function(queries) {
    // request all facets
    queries[0].params.facets = 'type';

    return search(queries);
  };
  return (
    <Box p="6" h="100vh">
      <InstantSearch searchClient={searchClient} indexName="recipes">
        <Configure hitsPerPage={20} />
        <Box display="flex" flexDirection="row">
          <Box width="200px">
          <ExperimentalDynamicWidgets>
              <CustomRefinementList attribute="type" defaultRefinement={['food']} />
            </ExperimentalDynamicWidgets>
          </Box>
          <Flex w="calc(100vw - 60px)" direction="column" align="center">
            <DebouncedSearchBox delay={400} />
            <CustomHits />
            <Pagination />
          </Flex>
        </Box>
      </InstantSearch>
    </Box>
  );
};

Recipes.propTypes = {
  firebase: PropTypes.shape().isRequired,
};

const condition = authUser => authUser;
const ComposedRecipes = compose(
  withFirebase,
  withAuthorization(condition)
)(Recipes);
export default ComposedRecipes;
