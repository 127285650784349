import React, { useState, useEffect } from "react"
import { Button } from "@chakra-ui/react"
import { Page, Text, View, Document, PDFDownloadLink, StyleSheet, Image, Link } from '@react-pdf/renderer';
import snakeCase from "lodash.snakecase"
import get from "lodash.get"
import truncate from "lodash.truncate"
import logoVertical from './logo/logo-vertical.png';
import logo from './logo/logo.png';
import { withFirebase } from "../../../utils/Firebase"

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    height: '100vh',
    flexWrap: 'wrap',
    paddingBottom: 25
  },
  infoSection: {
    padding: 10,
    flex: '0 0 100%',
  },
  infoDetailSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  detailSection: {
    paddingBottom: 10,
    marginLeft: 5,
    flex: '0 0 99%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    border: '1px solid #000',
  },
  detailContentSection: {
    width: '115px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  dataSection: {
    width: '240px',
    display: 'flex',
  },
  paxSection: {
    width: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "#4bafea"
  },
  paxWeightSection:{
    width: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "#f1cdb0"
  },
  ingredientsSection: {
    width: '1110px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    
  },
  columnSection: {
    width: '150px',
  }, 
  methodsSection: {
    width: '310px',
    display: 'flex',
  },
  utensilsSection: {
    width: '230px',
    display: 'flex',
  },
  formulasSection: {
    width: '200px',
    display: 'flex',
  }
});

const MyDoc = ({ recipe }) => {
  // if (!recipe) return null
  return (
    <Document style={{ width: '100%'}}>
      <Page orientation="landscape" size="LETTER" style={styles.page} wrap={false}>
        <View style={styles.infoSection} wrap={false} fixed>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingVertical: 5 }}>
            <Text style={{ fontSize: 14, marginBottom: 3, color: "#7F7B7A" }}>{recipe.name}</Text>
            <Image src={logoVertical} style={{ width: 80 }} fixed  />      
          </View>
          <View style={styles.infoDetailSection }>
            <View style={styles.detailContentSection}>
              <View>
                <Text style={{ fontSize: 10, fontWeight: 800, marginBottom: 5 }}>Número de Porciones:</Text>
              </View>
              <View>
                <Text style={{ fontSize: 10, fontWeight: 800, marginBottom: 5 }}>Peso Pax (g) Crudo:</Text>
              </View>
              <View>
                <Text style={{ fontSize: 10, fontWeight: 800, marginBottom: 5 }}>Peso total (g) (Crudo):</Text>
              </View>
            </View>
            <View style={styles.dataSection}>
              <View style={{display: 'flex', flexDirection: 'row'}}>
                <View style={{ width: 40 }}>
                  <Text style={{ fontSize: 10, marginBottom: 5, textAlign: 'center', backgroundColor: '#ea3324' }}>{recipe.servings}</Text>
                </View>
                <View style={styles.columnSection}>
                  <Text style={{ fontSize: 10, fontWeight: 800, marginBottom: 5 }}>Número de receta:</Text>
                </View>
                <View>
                </View>
              </View>
              <View style={{display: 'flex', flexDirection: 'row'}}>
                <View style={{ width: 40}}>
                  <Text style={{ fontSize: 10, marginBottom: 5, color: "#7F7B7A", textAlign: 'center' }}>{recipe.totalRawWeightNewServings}</Text>
                </View>
                <View style={styles.columnSection}>
                  <Text style={{ fontSize: 10, fontWeight: 800, marginBottom: 5 }}>Peso Pax Cocinado (g)</Text>
                </View>
                <View style={{ width: 40 }}>
                  <Text style={{ fontSize: 10, marginBottom: 5, textAlign: 'center', paddingHorizontal: 5, backgroundColor: '#ea3324' }}>{recipe.paxWeightCooked}</Text>
                </View>
              </View>
              <View style={{display: 'flex', flexDirection: 'row'}}>
                <View style={{ width: 40 }}>
                  <Text style={{ fontSize: 10, marginBottom: 5, color: "#7F7B7A", textAlign: 'center' }}>{recipe.totalWeightRaw}</Text>
                </View>
                <View style={styles.columnSection}>
                  <Text style={{ fontSize: 10, fontWeight: 800, marginBottom: 5 }}>Peso total en Gramos (Cocinado)</Text>
                </View>
                <View style={{ width: 40 }}>
                  <Text style={{ fontSize: 10, marginBottom: 5, textAlign: 'center', backgroundColor: '#ea3324' }}>{recipe.totalWeightCooked}</Text>
                </View>
              </View>
            </View>
            <View style={styles.paxSection}>
              <View>
                <Text style={{ fontSize: 12, fontWeight: 800, }}>Pax Nuevo</Text>
              </View>
              <View style={{ border: '1px solid #000', width: '100%'}}>
                <Text style={{ fontSize: 12, textAlign: 'center' }}>{recipe.newServings}</Text>
              </View>
            </View>
            <View style={styles.paxWeightSection}>
              <View>
                <Text style={{ fontSize: 12, fontWeight: 800, }}>Peso Pax Nuevo(g)</Text>
              </View>
              <View style={{ border: '1px solid #000', width: '100%'}}>
                <Text style={{ fontSize: 12, textAlign: 'center' }}>{recipe.totalWeightNewServings}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{...styles.detailSection, paddingBottom: 0, display: 'flex', justifyContent: 'flex-end', paddingRight: 5.5, border: 'none'}}>
          <View style={{ width: 45, backgroundColor: '#a0cd62', borderTop: '1px solid #000', borderLeft: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}><Text>F1</Text></View>
          <View style={{ width: 35, backgroundColor: '#fffe55', borderTop: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center'}}><Text>F2</Text></View>
        </View>
        <View style={{...styles.detailSection, paddingBottom: 0}}>
          <View style={styles.ingredientsSection}>
            <View style={{ display: 'flex', flexDirection: 'row'}}>
              <View style={{ width: 200 }}>
                <Text style={{ fontSize: 12, fontWeight: 800, borderRight: '1px solid #000', padding: 2 }}>Ingredientes: Paso 2</Text>
                {recipe && recipe.ingredients.map((i, o, cc) => <Text style={{ backgroundColor: i.isDivider ? '#4e749d' : 'transparent', color: i.isDivider ? 'white' : "#7F7B7A", border: '1px solid #000', borderLeft: 'none', borderBottom: cc.length - 1 === o ? '1px solid #000' : 'none', fontSize: 10, paddingHorizontal: 2, paddingTop: 6 }} key={`i-${o}`}>{i.name}</Text>)}
              </View>
              <View>
                <Text style={{ fontSize: 12, fontWeight: 800, borderRight: '1px solid #000', padding: 2 }}>Cantidad</Text>
                {recipe && recipe.ingredients.map((i, o, cc) => <Text style={{ backgroundColor: i.isDivider ? '#4e749d' : 'transparent', color: i.isDivider ? 'white' : "#7F7B7A", paddingLeft: 3, border: '1px solid #000', borderLeft: 'none', borderBottom: cc.length - 1 === o ? '1px solid #000' : 'none', fontSize: 10, paddingTop: 6 }} key={`w-${o}`}>{i.isDivider ? " " : i.weight}</Text>)}
              </View>
              <View>
                <Text style={{ fontSize: 12, fontWeight: 800, padding: 2 }}>Unidad</Text>
                {recipe && recipe.ingredients.map((i, o, cc) => <Text style={{ backgroundColor: i.isDivider ? '#4e749d' : 'transparent', color: i.isDivider ? 'white' : "#7F7B7A", paddingLeft: 3, border: '1px solid #000', borderLeft: 'none', borderRight: 'none', borderBottom: cc.length - 1 === o ? '1px solid #000' : 'none', fontSize: 10, paddingTop: 6 }} key={`g-${o}`}>{i.isDivider ? " " : "g"}</Text>)}
              </View>
              <View style={{ width: 90 }}>
                <Text style={{ fontSize: 12, fontWeight: 800, borderRight: '1px solid #000', borderLeft: '1px solid #000', padding: 2 }}>PC</Text>
                {recipe && recipe.ingredients.map((i, o, cc) => {
                  return <Text
                    wrap={false}
                    style={{
                      color: "#7F7B7A",
                      minHeight: cc.length - 1 === o ? 16 : 12,
                      paddingLeft: 3,
                      border: '1px solid #000',
                      borderLeft: '1px solid #000',
                      borderBottom: cc.length - 1 === o ? '1px solid #000' : 'none',
                      fontSize: 10,
                      paddingTop: 6,
                    }} key={`pc-${o}`}>{truncate(i.pc, { length: 18, omission: ".." }) || " "}</Text>
                })}
              </View>
              <View style={{ width: 85 }}>
                <Text style={{ width: '100%', fontSize: 12, fontWeight: 800, padding: 2 }}>P.C.C</Text>
                {recipe && recipe.ingredients.map((i, o, cc) => <Text style={{ width: '100%', color: "#7F7B7A", minHeight: cc.length - 1 === o ? 1 : 12, paddingLeft: 3, border: '1px solid #000', borderBottom: cc.length - 1 === o ? '1px solid #000' : 'none', borderRight: '0.5px solid #000', fontSize: 10, paddingTop: 6 }} key={`pcc-${o}`}>{truncate(i.pcc, { length: 16, omission: ".."}) || " "}</Text>)}
              </View>
            </View>
          </View>
          <View style={styles.methodsSection}>
            <View>
              <Text style={{ fontSize: 11, fontWeight: 800, borderLeft: '1px solid #000', borderRight: '1px solid #000', borderBottom: '1px solid #000', padding: 2 }}>Método cocción: Paso 3</Text>
              {recipe && recipe.cookingMethod.map((i, o) => <Text style={{ color: "#7F7B7A", border: '1px solid #000', borderLeft: '0.5px solid #000', borderTop: 'none', fontSize: 10, fontWeight: 300, padding: 3 }} key={`i-${o}`}>{i.description}</Text>)}
            </View>
            <View>
              <Text style={{ border: '1px solid #000', borderTop: 'none', fontSize: 11, fontWeight: 800, padding: 2 }}>Técnicas: Paso 4</Text>
              {recipe && recipe.techniques.map((i, o, cc) => <Text style={{ color: "#7F7B7A", borderLeft: '1px solid #000', borderRight: '1px solid #000', borderBottom: cc.length - 1 === o ? '0.5px solid #000' : '1px solid #000' , fontSize: 10, fontWeight: 300, padding: 3 }} key={`i-${o}`}>{i.description}</Text>)}
            </View>
          </View>
          <View style={styles.utensilsSection}>
            <Text style={{ fontSize: 11, fontWeight: 800, borderBottom: '1px solid #000', padding: 2}}>Menaje: Paso 5</Text>
            {recipe && recipe.utensils.map((i, o) => <Text style={{ color: "#7F7B7A", borderBottom: '1px solid #000', fontSize: 10, fontWeight: 300, padding: 3 }} key={`i-${o}`}>{i.description}</Text>)}
          </View>
          <View style={styles.formulasSection}>
            <View style={{ display: 'flex', flexDirection: 'row'}}>
              <View>
                <Text style={{ fontSize: 12, fontWeight: 800, borderLeft: '1px solid #000', borderBottom: '1px solid #000', padding: 2, backgroundColor: "#a0cd62" }}>{recipe.f1 || "F1"}</Text>
                {recipe && recipe.ingredients.map((i, o) => <Text style={{  borderBottom: '1px solid #000', borderLeft: '1px solid #000', fontSize: 10, fontWeight: 600, paddingTop: 6, paddingLeft: 2, paddingRight: 5, backgroundColor: "#a0cd62" }} key={`i-${o}`}>{i.isDivider ? "0" : i.f1}</Text>)}
              </View>
              <View>
                <Text style={{ fontSize: 12, fontWeight: 800, borderLeft: '1px solid #000', borderRight: '1px solid #000', borderBottom: '1px solid #000', padding: 2, backgroundColor: "#fffe55"}}>{recipe.f2 || "F2"}</Text>
                {recipe && recipe.ingredients.map((i, o) => <Text style={{ borderBottom: '1px solid #000', borderLeft: '1px solid #000', borderRight: '1px solid #000', fontSize: 10, fontWeight: 600, paddingTop: 6, paddingLeft: 2, paddingRight: 5, backgroundColor: "#fffe55" }} key={`w-${o}`}>{i.isDivider ? "0" : i.f2}</Text>)}
              </View>
            </View>
          </View>
        </View>
        <View style={{...styles.detailSection, paddingBottom: 0, borderTop: 'none'}}>
          <View style={{ width: 200 }}>
            <Text style={{ fontSize: 12, paddingLeft: 2, borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>Información Nutricional por Porción</Text>
            <View style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #000' }}>
              <View style={{ width: 100, borderRight: '1px solid #000' }}>
                <Text style={{ paddingLeft: 2, fontSize: 10 }}>Energía</Text>
              </View>
              <View style={{ flex: 1, borderRight: '1px solid #000' }}>
                <Text style={{ color: "#7F7B7A", paddingLeft: 2, fontSize: 10 }}>{get(recipe, ["nutritional", "energy", "value"])}</Text>
              </View>
              <View style={{ flex: 1, borderRight: '1px solid #000' }}>
                <Text style={{ color: "#7F7B7A", paddingLeft: 2, fontSize: 10 }}>{get(recipe, ["nutritional", "energy", "unit"])}</Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #000' }}>
              <View style={{ width: 100, borderRight: '1px solid #000' }}>
                <Text style={{ paddingLeft: 2, fontSize: 10 }}>Carbohidratos</Text>
              </View>
              <View style={{ flex: 1, borderRight: '1px solid #000' }}>
                <Text style={{ color: "#7F7B7A", paddingLeft: 2, fontSize: 10 }}>{get(recipe, ["nutritional", "carbohydrates", "value"])}</Text>
              </View>
              <View style={{ flex: 1, borderRight: '1px solid #000' }}>
                <Text style={{ color: "#7F7B7A", paddingLeft: 2, fontSize: 10 }}>{get(recipe, ["nutritional", "carbohydrates", "unit"])}</Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #000' }}>
              <View style={{ width: 100, borderRight: '1px solid #000' }}>
                <Text style={{ paddingLeft: 2, fontSize: 10 }}>Proteína</Text>
              </View>
              <View style={{ flex: 1, borderRight: '1px solid #000' }}>
                <Text style={{ color: "#7F7B7A", paddingLeft: 2, fontSize: 10 }}>{get(recipe, ["nutritional", "protein", "value"])}</Text>
              </View>
              <View style={{ flex: 1, borderRight: '1px solid #000' }}>
                <Text style={{ color: "#7F7B7A", paddingLeft: 2, fontSize: 10 }}>{get(recipe, ["nutritional", "protein", "unit"])}</Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #000' }}>
              <View style={{ width: 100, borderRight: '1px solid #000' }}>
                <Text style={{ paddingLeft: 2, fontSize: 10 }}>Fibra</Text>
              </View>
              <View style={{ flex: 1, borderRight: '1px solid #000' }}>
                <Text style={{ color: "#7F7B7A", paddingLeft: 2, fontSize: 10 }}>{get(recipe, ["nutritional", "fiber", "value"])}</Text>
              </View>
              <View style={{ flex: 1, borderRight: '1px solid #000' }}>
                <Text style={{ color: "#7F7B7A", paddingLeft: 2, fontSize: 10 }}>{get(recipe, ["nutritional", "protein", "unit"])}</Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #000' }}>
              <View style={{ width: 100, borderRight: '1px solid #000' }}>
                <Text style={{ paddingLeft: 2, fontSize: 10 }}>Grasa</Text>
              </View>
              <View style={{ flex: 1, borderRight: '1px solid #000' }}>
                <Text style={{ color: "#7F7B7A", paddingLeft: 2, fontSize: 10 }}>{get(recipe, ["nutritional", "fat", "value"])}</Text>
              </View>
              <View style={{ flex: 1, borderRight: '1px solid #000' }}>
                <Text style={{ color: "#7F7B7A", paddingLeft: 2, fontSize: 10 }}>{get(recipe, ["nutritional", "fat", "unit"])}</Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #000' }}>
              <View style={{ width: 100, borderRight: '1px solid #000' }}>
                <Text style={{ paddingLeft: 2, fontSize: 10 }}>Colesterol</Text>
              </View>
              <View style={{ flex: 1, borderRight: '1px solid #000' }}>
                <Text style={{ color: "#7F7B7A", paddingLeft: 2, fontSize: 10 }}>{get(recipe, ["nutritional", "cholesterol", "value"])}</Text>
              </View>
              <View style={{ flex: 1, borderRight: '1px solid #000' }}>
                <Text style={{ color: "#7F7B7A", paddingLeft: 2, fontSize: 10 }}>{get(recipe, ["nutritional", "cholesterol", "unit"])}</Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #000' }}>
              <View style={{ width: 100, borderRight: '1px solid #000' }}>
                <Text style={{ paddingLeft: 2, fontSize: 10 }}>Sodio</Text>
              </View>
              <View style={{ flex: 1, borderRight: '1px solid #000' }}>
                <Text style={{ color: "#7F7B7A", paddingLeft: 2, fontSize: 10 }}>{get(recipe, ["nutritional", "sodium", "value"])}</Text>
              </View>
              <View style={{ flex: 1, borderRight: '1px solid #000' }}>
                <Text style={{ color: "#7F7B7A", paddingLeft: 2, fontSize: 10 }}>{get(recipe, ["nutritional", "sodium", "unit"])}</Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #000' }}>
              <View style={{ flex: 1, borderRight: '1px solid #000' }}>
                <Text style={{ paddingLeft: 2, fontSize: 10 }}>Obervaciones</Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View style={{ flex: 1, borderRight: '0.5px solid #000' }}>
                <Text style={{ color: "#7F7B7A", paddingLeft: 2, paddingTop: 5, fontSize: 10 }}>{get(recipe, ["nutritional", "observations"], "")}</Text>
              </View>
            </View>
          </View>
          {/* Recipe Steps */}
          <View style={{ flex: 1 }}>
            {recipe && recipe.recipeSteps.map((i, o, cc) => {
              return <Text style={{ color: i.isDivider ? "#000" : "#7F7B7A", borderBottom: cc.length - 1 !== o ? '1px solid #000' : 'none', fontSize: 10, fontWeight: 300, paddingLeft: 3, borderLeft: '0.5px solid #000' }} key={`i-${o}`}>{i.description}</Text>
            })}
          </View>
        </View>
        {/* Footer */}
        <View style={{ position: 'absolute', bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw' }} fixed>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', width: '100vw', height: 25 }}>
            <Image src={logo} style={{ height: 15 }} />
            <Text style={{ fontSize: 8, padding: 5 }}>Correo electrónico: info@cheffrancr.com </Text>
            <Link style={{ fontSize: 8, padding: 5 }} src="http://cheffrancr.com/">Página Web</Link>
            <Link style={{ fontSize: 8, padding: 5 }} src="https://www.facebook.com/Cheffrancr">Facebook: cheffrancr</Link>
            <Link style={{ fontSize: 8, padding: 5 }} src="https://www.instagram.com/cheffran_cr">Instagram: cheffran_cr</Link>
            <Image src={logo} style={{ height: 15 }} />
          </View>
        </View>
      </Page>
    </Document>
  )
};

const GeneratePdf = ({ firebase, recipe }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [currentRecipe, setCurrentRecipe] = useState(null)

  useEffect(() => {
    const getRecipe = async () => {
      // const { state } = location
      setCurrentRecipe(recipe)
      setIsLoading(false)
    }
    if (recipe && firebase) {
      getRecipe()
    }
  }, [recipe, firebase]);

  return (
    <>
    <Button >
      {!isLoading && (
        <>
        {/* <MyDoc recipe={currentRecipe}/> */}
          {currentRecipe && <PDFDownloadLink document={<MyDoc recipe={currentRecipe}/>} fileName={`${snakeCase(currentRecipe.name)}.pdf`}>
            {({ blob, url, loading, error }) =>
              loading ? 'Generando...' : 'Descargar'
            }
          </PDFDownloadLink>}
        </>
      )}
    </Button>
    </>
  )
}

const ConnectedGeneratePdf = withFirebase(GeneratePdf)

export default ConnectedGeneratePdf