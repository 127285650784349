import React from "react"
import { Flex } from "@chakra-ui/react"
import LoginForm from "../../components/organisms/LoginForm"

const Login = () => (
  <Flex w="calc(100vw - 60px);" h="100vh" p={3} className="login">
    <LoginForm />
  </Flex>
)

export default Login