import React, { useContext } from "react"
import { useHistory } from "react-router-dom"
import { Flex, Box, VStack, IconButton, useMediaQuery } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { SettingsIcon, ViewIcon } from "@chakra-ui/icons"

import {
  MdExitToApp,
} from "react-icons/md"
import Logo from "../../atoms/Logo"
import * as ROUTES from "../../../utils/routes"
import { withFirebase } from "../../../utils/Firebase"
import { AuthUserContext } from "../../../context/Session"

function Layout(props) {
  const authUser = useContext(AuthUserContext)
  const history = useHistory()
  const { children, firebase } = props
  const allowedUser = !!authUser && !history.location.pathname.includes("login")
  const [isPrint] = useMediaQuery("print")
  return (
    <Flex
      w="100vw"
      h="100vh"
      overflow="hidden"
      position="relative"
      justify="center"
      align="center"
      className="main"
      bgColor={history.location.pathname.includes("login") ? "brand.black" : "brand.background"}
    >

      {allowedUser && !isPrint && (
        <Flex
          h="100%"
          w="60px"
          overflow="hidden"
          className="sidebar"
          borderRight="1px"
          borderColor="gray.200"
          py="10"
          px="2"
          direction="column"
          align="center"
          _
        >
          <VStack spacing="6">
            {/* {(get(authUser, ["roles", "ADMIN"]) ||
              get(authUser, ["roles", "SUPER_ADMIN"]) ||
              get(authUser, ["roles", "PROVIDER"]) ||
              get(authUser, ["roles", "USER"])) && (
              <IconButton
                colorScheme="teal"
                aria-label="Calendario"
                icon={<CalendarIcon />}
                variant="ghost"
                _hover={{ bg: "#ebedf0" }}
                onClick={() => history.push(ROUTES.DASHBOARD)}
              />
            )}
            {!get(authUser, ["roles", "PROVIDER"]) && (
              <IconButton
                colorScheme="teal"
                aria-label="Clientes"
                icon={<MdPerson />}
                variant="ghost"
                _hover={{ bg: "#ebedf0" }}
                onClick={() => history.push(ROUTES.CLIENTS)}
              />
            )}
            {(get(authUser, ["roles", "MARKETING"]) ||
              get(authUser, ["roles", "SUPER_ADMIN"])) && (
              <IconButton
                colorScheme="teal"
                aria-label="Marketing"
                icon={<MdInsertChart />}
                variant="ghost"
                _hover={{ bg: "#ebedf0" }}
                onClick={() => history.push(ROUTES.MARKETING)}
              />
            )}
            {(get(authUser, ["roles", "ADMIN"]) ||
              get(authUser, ["roles", "SUPER_ADMIN"])) && (
              <IconButton
                colorScheme="teal"
                aria-label="Administración"
                icon={<MdVerifiedUser />}
                variant="ghost"
                _hover={{ bg: "#ebedf0" }}
                onClick={() => history.push(ROUTES.ADMINISTRATION)}
              />
            )} */}
            
            <IconButton
              color="brand.corona"
              aria-label="Recetas"
              icon={<ViewIcon />}
              variant="ghost"
              _hover={{ bg: "#ebedf0" }}
              onClick={() => history.push(ROUTES.RECIPES)}
            />
            <IconButton
              color="brand.corona"
              aria-label="Configuración"
              icon={<SettingsIcon />}
              variant="ghost"
              _hover={{ bg: "#ebedf0" }}
              onClick={() => history.push(ROUTES.SETTINGS)}
            />
            <IconButton
              color="brand.corona"
              aria-label="Salir"
              icon={<MdExitToApp />}
              variant="ghost"
              _hover={{ bg: "#ebedf0" }}
              onClick={() => {
                firebase.doSignOut()
                history.push(ROUTES.LOGIN)
              }}
            />
          </VStack>
        </Flex>
      )}
      <Box
        w={
          !history.location.pathname.includes("login")
            ? "calc(100% - 60px)"
            : "100%"
        }
        h="100%"
        className="content"
      >
        {children}
        <Logo isVertical position="absolute" bottom="3" right="5" w="10vw" />
      </Box>
    </Flex>
  )
}

Layout.propTypes = {
  firebase: PropTypes.shape().isRequired,
  children: PropTypes.element.isRequired,
}

export default withFirebase(Layout)
