import app from "firebase/app"
import "firebase/auth"
import "firebase/database"
import "firebase/firestore"
import "firebase/storage"
import dayjs from "dayjs"
import get from "lodash.get"
import { v4 as uuidv4 } from "uuid"
import config from "./config"

class Firebase {
  constructor() {
    app.initializeApp(config)
    this.auth = app.auth()
    this.db = app.database()
    this.firestore = app.firestore()
    this.storage = app.storage()
  }

  async getIdToken() {
    return this.auth.currentUser.getIdToken()
  }

  // *** Auth API ***
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doSignOut = () => this.auth.signOut()

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email)

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password)

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.email)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data()
            
            // merge auth and db user
            const newAuthUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            }
            next(newAuthUser)
          })
      } else {
        fallback()
      }
    })

  // *** User API ***

  user = (email) => this.firestore.collection("users").doc(email)

  async getRecipe({ recipeId }) {
    const recipe = await this.firestore.collection("recipes").doc(recipeId).get()
    return recipe.data()
  }

  getUsersByType = async (type) =>
    this.firestore.collection("users").where("userType", "==", type).get()

  getUserForm = async (email, form) =>
    this.firestore
      .collection("users")
      .doc(email)
      .collection("forms")
      .doc(form)
      .get()

  users = () => this.firestore.collection("users")

  chats = () => this.firestore.ref("chats")

  saveUserData = async ({ email, data }) => {
    const newUserRef = this.firestore.doc(`users/${email}`)
    return newUserRef.set(
      {
        ...data,
        indexDocument: true,
      },
      { merge: true }
    )
  }

  saveNewOrg = async ({ data }) => {
    const newOrgRef = this.firestore.collection("organizations").doc()
    await newOrgRef.set({
      ...data,
    })
    return newOrgRef.id
  }

  org = (id) => this.firestore.collection("organizations").doc(id)

  getOrgsByType = async (type, id) => {
    if (id) {
      return this.firestore.collection("organizations").doc(id).get()
    }
    return this.firestore
      .collection("organizations")
      .where("type", "==", type)
      .get()
  }

  getOrgForm = async (id, type) =>
    this.firestore.collection("organizations").doc(id).collection(type).get()

  addOrgProduct = async ({ orgId, productData }) => {
    const newProductRef = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("products")
      .doc()
    return newProductRef.set({
      ...productData,
    })
  }

  addOrgColaborator = async ({ email, orgId, agentData }) => {
    const newUserRef = this.firestore.doc(
      `organizations/${orgId}/agents/${email}`
    )
    return newUserRef.set({
      ...agentData,
    })
  }

  uploadToStorage(image) {
    const extension = image.name.split(".")
    const uidName = uuidv4()
    return this.storage.ref(`/images/${uidName}.${extension[1]}`).put(image)
  }

  //  new utils
  getProviders = async () =>
    this.firestore.collection("providers").where("active", "==", true).get()

  getAppointments = async (providerId) =>
    // this.firestore.collection("providers").where("active", "==", true).get()
    this.firestore
      .collection("providers")
      .doc(providerId)
      .collection("appointments")
      .where("timestampDate", ">=", dayjs().startOf("year").valueOf())
      .where("timestampDate", "<=", dayjs().endOf("year").valueOf())
      .get()

  getAppointmentById = async (providerId, eventId) =>
    this.firestore
      .collection("providers")
      .doc(providerId)
      .collection("appointments")
      .doc(eventId)
      .get()

  getTypes = async () =>
    this.firestore.collection("types").where("active", "==", true).get()

  getClients = async (searchText) =>
    // this.firestore.collection("providers").where("active", "==", true).get()
    this.firestore
      .collection("clients")
      .where("legalId", "==", searchText)
      .get()

  addAppointment = async (data, providerId) => {
    const newAptRef = this.firestore
      .collection("providers")
      .doc(providerId)
      .collection("appointments")
      .doc()
    await newAptRef.set({
      ...data,
    })
    return {
      id: newAptRef.id,
      duration: data.duration,
      timestampDate: data.timestampDate,
    }
  }

  addClients = async (data) => {
    const token = await this.auth.currentUser.getIdToken()
    const newClientRef = this.firestore.collection("clients").doc()
    await newClientRef.set({
      displayName: `${data.name} ${data.lastName}`,
      ...data,
    })
    return {
      token,
      id: newClientRef.id,
    }
  }

  updateClient = async (data) => {
    const { objectID, _highlightResult, ...otherValues } = data
    const newClientRef = this.firestore.doc(`clients/${objectID}`)
    return newClientRef.set(
      {
        displayName: `${otherValues.name} ${otherValues.lastName}`,
        ...otherValues,
      },
      { merge: true }
    )
  }

  updateAppointment = async (data, provider, appt) => {
    const apptRef = this.firestore.doc(
      `providers/${provider}/appointments/${appt}`
    )
    return apptRef.set(
      {
        ...data,
      },
      { merge: true }
    )
  }

  getMarketingLists = async () =>
    this.firestore.collection("marketingLists").get()

  getContactsMarketingList = async (listId) =>
    this.firestore
      .collection("marketingLists")
      .doc(listId)
      .collection("contacts")
      .get()

  addMarketingLists = async (data) => {
    const newList = this.firestore.collection("marketingLists").doc()
    await newList.set(data)
    return {
      id: newList.id,
      ...data,
    }
  }

  deleteMarketingList = async (id) => {
    await this.firestore.collection("marketingLists").doc(id).delete()
  }

  searchClientsByAppointmentsType = async (reasons) => {
    // const results = []
    try {
      const providers = await this.firestore
        .collection("providers")
        .where("active", "==", true)
        .get()
      if (providers && providers.docs) {
        const apptPromises = []
        providers.docs.forEach(async (d) => {
          apptPromises.push(
            this.firestore
              .collection("providers")
              .doc(d.id)
              .collection("appointments")
              .where("timestampDate", "<=", dayjs().endOf("month").valueOf())
              .where("reasonId", "in", reasons)
              .get()
          )
        })
        const valuesProm = await Promise.all(apptPromises)
        const parsedValues = valuesProm.map((v) => {
          if (v.docs.length > 0) {
            return v.docs.map(async (vd) => {
              const data = vd.data()
              try {
                // const clientData = await index.getObject(vd.id, {
                //   attributesToRetrieve: ["gender", "birthday"],
                // })
                // console.log("clientData", vd.id, clientData)
                return {
                  id: data.clientLegalId,
                  name: data.client,
                  email: data.clientEmail,
                  phoneNumber: data.clientPhoneNumber,
                  mobileNumber: data.clientMobileNumber,
                  gender: get(data, "gender", "A"),
                  birthday: get(data, "birthday", "A"),
                  objectId: vd.id,
                }
              } catch {
                return {
                  id: data.clientLegalId,
                  name: data.client,
                  email: data.clientEmail,
                  phoneNumber: data.clientPhoneNumber,
                  mobileNumber: data.clientMobileNumber,
                  gender: get(data, "clientGender", "A"),
                  birthday: null,
                  objectId: vd.id,
                }
              }
            })
          }
          return null
        })
        const flattenValues = parsedValues.filter((p) => p).flat(1)
        const key = "id"
        const arrayUniqueByKey = [
          ...new Map(flattenValues.map((item) => [item[key], item])).values(),
        ]
        // const unique = [...new Set(flattenValues.map((item) => item.id))]
        // console.log("return =>", arrayUniqueByKey)
        return arrayUniqueByKey
      }
      return []
    } catch (error) {
      // console.log(error)
      return []
    }
  }

  addClientsToMarketingList = async (listId, clients) => {
    const doc = await this.firestore
      .collection("marketingLists")
      .doc(listId)
      .get()
    try {
      let currentTotal = doc.data().totalContacts
      const batch = this.firestore.batch()
      clients.forEach((cl) => {
        currentTotal += 1
        const newContact = this.firestore
          .collection("marketingLists")
          .doc(listId)
          .collection("contacts")
          .doc()
        batch.set(newContact, cl)
      })

      const sfRef = this.firestore.collection("marketingLists").doc(listId)
      batch.update(sfRef, { totalContacts: currentTotal })
      await batch.commit()
    } catch (error) {
      console.log(error)
    }
  }
}

export default Firebase
