import React from "react"
import PropTypes from "prop-types"
import AuthUserContext from "./context"
import { withFirebase } from "../../utils/Firebase"

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        authUser: JSON.parse(localStorage.getItem("authUserChef")),
      }
    }

    componentDidMount() {
      const { firebase } = this.props
      this.listener = firebase.onAuthUserListener(
        async (authUser) => {
          try {
            const token = await firebase.getIdToken()
            if (token) {
              localStorage.setItem("authUserChef", JSON.stringify(authUser))
              this.setState({ authUser })
            }
          } catch (e) {
            console.log("e", e)
          }
        },
        () => {
          localStorage.removeItem("authUserChef")
          this.setState({ authUser: null })
        }
      )
    }

    componentWillUnmount() {
      this.listener()
    }

    setUser = (editedUser) => {
      this.setState(editedUser)
    }

    render() {
      const { authUser } = this.state
      return (
        <AuthUserContext.Provider
          value={
            authUser
              ? {
                  ...authUser,
                  setUser: (editedUser) => {
                    localStorage.setItem(
                      "authUserChef",
                      JSON.stringify(editedUser)
                    )
                    this.setState({ authUser: editedUser })
                  },
                }
              : null
          }
        >
          <Component {...this.props} />
        </AuthUserContext.Provider>
      )
    }
  }

  WithAuthentication.propTypes = {
    firebase: PropTypes.shape().isRequired,
  }
  return withFirebase(WithAuthentication)
}

export default withAuthentication
