import React, { useState } from "react"
import { Badge, Box, Image, Text, Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from "@chakra-ui/react"
import { StarIcon } from "@chakra-ui/icons"
import { Link } from "react-router-dom"
import { Highlight } from 'react-instantsearch-dom';
import { DeleteIcon } from "@chakra-ui/icons"
import CustomHighlight from '../../atoms/CustomHighlight'
import { withFirebase } from "../../../utils/Firebase"
import { useToasts } from "react-toast-notifications"

const Recipe = ({ hit, firebase, onRecipeDelete }) => {
  const { addToast } = useToasts()
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const onClose = () => setIsOpen(false)
  const cancelRef = React.useRef()
  const property = {
    reviewCount: 34,
    rating: 3,
  }
  return (
    
      <Box position="relative" cursor="pointer" borderColor={hit.new ? "brand.corona" : "white"} borderWidth="5px" borderRadius="lg" overflow="hidden" shadow="md" w={["280px", "280px", "290px", "320px"]} h="360px" display="flex" flexDirection="column" >
        <Box _hover={{
          transform: "scale3d(1.2, 1.2, 1.2)"
        }} zIndex="overlay" position="absolute" right="3" bottom="2" type="button" onClick={() => setIsOpen(true)} colorScheme="white" variant="outline" ><DeleteIcon color="brand.corona" m="0" /></Box>
        <Link to={hit.new ? "/new-recipe" : { pathname: `/recipes/${hit.objectID}`, state:{hit}}}>
        <Image objectFit="cover" src={hit.imageUrl} alt={hit.name} fallbackSrc="http://via.placeholder.com/800x550?text=Cargando..." height="215px" maxHeight="215px" margin="0 auto" />
        <Box px="6" py="1">
          <Box
            mt="1"
            fontWeight="semibold"
            as="h1"
            lineHeight="tight"
            isTruncated
            textAlign="left"
            color="white"
          >
            {!hit.new && <Highlight attribute="name" hit={hit} tagName="mark" />}
            {hit.new && <span>{hit.name}</span>}
          </Box>
          {!hit.new &&
            <Box d="flex" alignItems="baseline">
              {/* <Badge borderRadius="full" px="2" color="brand.corona">
                New
              </Badge> */}
              <Box
                textAlign="left"
                color="brand.white"
                fontWeight="semibold"
                letterSpacing="wide"
                fontSize="xs"
                textTransform="uppercase"
              >
                {hit.categories.split(",").map((c, i) => (
                  <Badge fontSize="xx-small" key={`cat-${i}`} borderRadius="full" px="2" mr="1" color="brand.corona">
                    {c}
                  </Badge>))}
              </Box>
            </Box>
          }
          {!hit.new &&
            <Box d="flex" alignItems="baseline" mt="2">
              <Text
                isTruncated
                color="brand.white"
                fontWeight="semibold"
                letterSpacing="wide"
                fontSize="xs"
                textTransform="uppercase"
              >
                {/* {hit.ingredients} */}
                <CustomHighlight attribute="ingredients" hit={hit} tagName="mark" />
              </Text>
            </Box>
          }
          

          {!hit.new && <Box d="flex" mt="2" alignItems="center">
            {Array(5)
              .fill("")
              .map((_, i) => (
                <StarIcon
                  key={i}
                  color={i < property.rating ? "brand.corona" : "gray.300"}
                />
              ))}
            <Box as="span" ml="2" color="gray.600" fontSize="sm">
              {property.reviewCount} reviews
            </Box>
          </Box>}
        </Box>
        </Link>
        <AlertDialog
          motionPreset="slideInBottom"
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Borrar Receta
              </AlertDialogHeader>

              <AlertDialogBody>
                Esta seguro que desea borrar la receta?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancelar
                </Button>
                <Button isLoading={isDeleting} colorScheme="red" onClick={async () => {
                  try {
                    setIsDeleting(true)
                    const authToken = await firebase.getIdToken()
                    const deletedRecipe = await fetch("https://us-central1-chef-fran.cloudfunctions.net/recipesDelete", {
                      method: "POST",
                      body: JSON.stringify({recipeId: hit.objectID }, null, 2),
                      headers: {
                        Authorization: `Bearer ${authToken}`,
                        "Content-Type": "application/json",
                      },
                    })
                    const data = await deletedRecipe.json()
                    if (data.error) {
                      setIsDeleting(false)
                      addToast("Ocurrio un error inesperado, intenta nuevamente", { appearance: "error" })
                    } else {
                      onRecipeDelete(hit.objectID)
                      onClose()
                      addToast("Receta eliminada correctamente", { appearance: "success" })
                      setIsDeleting(false)
                    }
                  } catch (error) {
                    setIsDeleting(false)
                    addToast("Ocurrio un error inesperado, intenta nuevamente", { appearance: "error" })
                  }
                }} ml={3}>
                  Eliminar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
  )
}

export default withFirebase(Recipe)