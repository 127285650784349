import React from 'react';
import {
  Box,
  Flex,
  Heading,
  SimpleGrid,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Badge,
  Button,
} from '@chakra-ui/react';
import Generate from '../GeneratePdf';

const Summary = ({ recipe, recipeId }) => {
  const type = recipe.type || 'food';
  return (
    <div>
      <Heading size="md" color="brand.corona" mb="5">
        {recipe.name}
      </Heading>
      <Box d="flex" w="100%" justifyContent="center" alignItems="center">
        <Box
          textAlign="left"
          color="brand.white"
          fontWeight="semibold"
          letterSpacing="wide"
          fontSize="xs"
          textTransform="uppercase"
        >
          {recipe.categories.map((c, o) => (
            <Badge
              key={`bg-${o}`}
              borderRadius="full"
              px="2"
              mr="1"
              color="brand.corona"
            >
              {c.name}
            </Badge>
          ))}
        </Box>
      </Box>
      <SimpleGrid columns="2">
        <Box borderWidth="medium" borderRadius="md" m="2">
          <Flex direction="column">
            <Heading size="md" color="gray.500">
              Datos Generales
            </Heading>
            <SimpleGrid columns="2" p="2">
              <Box textAlign="left" as="span" fontSize="md">
                Porciones: {recipe.servings}
              </Box>
              <Box textAlign="left" as="span" fontSize="md">
                Pax Nuevo: {recipe.newServings}
              </Box>
              <Box textAlign="left" as="span" fontSize="md">
                Peso Pax Nuevo(g): {recipe.totalWeightNewServings}
              </Box>
              {type === 'drinks' && (
                <>
                <Box textAlign="left" as="span" fontSize="md">
                  Metabolización: {recipe.metaTime}
                </Box>
                <Box textAlign="left" as="span" fontSize="md" fontWeight="bold">
                  Estilo: {recipe.drinkStyle}
                </Box>
                </>
              )}
              {type === 'food' && (
                <>
                  <Box textAlign="left" as="span" fontSize="md">
                    Peso Pax(g) Crudo: {recipe.totalRawWeightNewServings}
                  </Box>
                  <Box textAlign="left" as="span" fontSize="md">
                    Peso total Cocinado(g): {recipe.totalWeightCooked}
                  </Box>
                  <Box textAlign="left" as="span" fontSize="md">
                    Peso total(g) Crudo: {recipe.totalWeightRaw}
                  </Box>
                  <Box textAlign="left" as="span" fontSize="md">
                    Peso Pax Cocinado(g): {recipe.paxWeightCooked}
                  </Box>
                  <Box textAlign="left" as="span" fontSize="md">
                    F1: {recipe.f1}
                  </Box>
                  <Box textAlign="left" as="span" fontSize="md">
                    F2: {recipe.f2}
                  </Box>
                </>
              )}
            </SimpleGrid>
          </Flex>
        </Box>
        <Box borderWidth="medium" borderRadius="md" m="2">
          <Flex direction="column">
            <Heading size="md" color="gray.500">
              Ingredientes
            </Heading>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Nombre</Th>
                  <Th>Peso(g)</Th>
                  <Th>F1</Th>
                  <Th>F2</Th>
                </Tr>
              </Thead>
              <Tbody>
                {recipe.ingredients.map((i, o) => (
                  <Tr key={`in-${o}`}>
                    <Td>{i.name}</Td>
                    <Td>{i.weight}</Td>
                    <Td>{i.f1}</Td>
                    <Td>{i.f2}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Flex>
        </Box>
        <Box borderWidth="medium" borderRadius="md" m="2" gridColumn="span 2">
          <Flex direction="column">
            <Heading size="md" color="gray.500">
              Preparación
            </Heading>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Descripción</Th>
                </Tr>
              </Thead>
              <Tbody>
                {recipe.recipeSteps.length > 0 ? (
                  recipe.recipeSteps.map((i, o) => (
                    <Tr key={`rs-${o}`}>
                      <Td>{i.description}</Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td>N/A</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Flex>
        </Box>
        {type === 'food' && (
          <Box borderWidth="medium" borderRadius="md" m="2">
            <Flex direction="column">
              <Heading size="md" color="gray.500">
                Método de cocción
              </Heading>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Descripción</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {recipe.cookingMethod.length > 0 ? (
                    recipe.cookingMethod.map((i, o) => (
                      <Tr key={`cm-${o}`}>
                        <Td>{i.description}</Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td>N/A</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Flex>
          </Box>
        )}
        <Box borderWidth="medium" borderRadius="md" m="2">
          <Flex direction="column">
            <Heading size="md" color="gray.500">
              Técnicas
            </Heading>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Descripción</Th>
                </Tr>
              </Thead>
              <Tbody>
                {recipe.techniques.length > 0 ? (
                  recipe.techniques.map((i, o) => (
                    <Tr key={`tec-${o}`}>
                      <Td>{i.description}</Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td>N/A</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Flex>
        </Box>
        <Box borderWidth="medium" borderRadius="md" m="2">
          <Flex direction="column">
            <Heading size="md" color="gray.500">
              Menaje
            </Heading>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Descripción</Th>
                </Tr>
              </Thead>
              <Tbody>
                {recipe.utensils.length > 0 ? (
                  recipe.utensils.map((i, o) => (
                    <Tr key={`uten-${o}`}>
                      <Td>{i.description}</Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td>N/A</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Flex>
        </Box>
        <Flex
          alignItems="center"
          justifyContent="center"
          borderWidth="medium"
          borderRadius="md"
          m="2"
        >
          <Generate recipe={recipe} />
          <Button m="2" onClick={() => console.log('PDF')}>
            Clonar Receta
          </Button>
        </Flex>
      </SimpleGrid>
    </div>
  );
};

export default Summary;
