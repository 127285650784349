import { extendTheme } from "@chakra-ui/react"

const colors = {
  brand: {
    black: "#000000",
    white: "#ffffff",
    background: "#565253",
    corona: "#B99217",
    font: {
      gray: "#7F7B7A"
    }
  },
}

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
}

const textStyles = {
  h1: {
    color: colors.brand.white,
  },
  input: {
    color: colors.brand.white,
  }
}

const components = {}

const customTheme = extendTheme({ colors, config, textStyles, components })

export default customTheme