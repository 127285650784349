import React from "react"
import PropTypes from "prop-types"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch, Configure } from "react-instantsearch-dom"
import { useFormikContext, useField } from 'formik';
import Autocomplete from "../../atoms/Autocomplete"

// const searchClient = algoliasearch(
//   process.env.REACT_APP_ALGOLIA_APP_ID,
//   process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
// )
const searchClient = algoliasearch('76T3GSDQWX', 'b4d4f4afe153636cea675592b321f806');

const ItemSearch = ({
  placeholder,
  onSelectedProduct,
  onAnySugestions: onAnySugestionsHandler,
  onClearProduct,
  indexName,
  indexField,
  customHitsPerPage,
  ...rest
}) => {
  const {
    setFieldValue,
  } = useFormikContext();

  const [field] = useField(rest);

  // eslint-disable-next-line no-unused-vars
  const [selectedProduct, setSelectedProduct] = React.useState({})

  const onAnySugestions = (_, { suggestion }) => {
    setSelectedProduct(suggestion)
    setFieldValue(field.name, suggestion[indexField]);
    setFieldValue(field.name.replace(indexField, 'id'), undefined);
    if (onAnySugestionsHandler) {
      onAnySugestionsHandler(suggestion)
    }
  }

  const onSuggestionSelected = (_, { suggestion }) => {
    setSelectedProduct(suggestion)
    setFieldValue(field.name, suggestion[indexField]);
    setFieldValue(field.name.replace(indexField, 'id'), suggestion.objectID);
    if (onSelectedProduct) {
      onSelectedProduct(suggestion)
    }
  }

  const onSuggestionCleared = () => {
    if (onClearProduct) {
      onClearProduct()
    }
    setFieldValue(field.name, undefined);
    setFieldValue(field.name.replace(indexField, 'id'), undefined);
    setSelectedProduct({})
    onSelectedProduct({
      [indexField]: "",
      active: true
    })
  }

  return (
    <InstantSearch
      place
      // indexName={process.env.REACT_APP_ALGOLIA_INDEX_NAME}
      indexName={indexName}
      searchClient={searchClient}
    >
      <Configure hitsPerPage={customHitsPerPage || 5} />
      <Autocomplete
        defaultValue={rest.value}
        placeholder={placeholder}
        onAnySuggestions={onAnySugestions}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionCleared={onSuggestionCleared}
        indexField={indexField}
      />
    </InstantSearch>
  )
}

ItemSearch.defaultProps = {
  name: "",
  onSelectedProduct: () => null,
  onClearProduct: () => null,
  onAnySugestions: () => null,
  indexField: "name"
}

ItemSearch.propTypes = {
  onSelectedProduct: PropTypes.func,
  onClearProduct: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  onAnySugestions: PropTypes.func,
  name: PropTypes.string,
}

export default ItemSearch
